import React from "react";
import AlertBox from "./alertbox";

const OtherAuctionAlert = ({ hasLinkedSaleMessage }) => {
	if (!hasLinkedSaleMessage) return null;

	return (
		<div className="auction-alerts text-center">
			<AlertBox
				message={{
					severity: "warning",
					subject: "Additional Taxes For Sale",
					body:
						"Additional taxes for this property are being sold in another auction. See the seller notes below for details."
				}}
			/>
		</div>
	);
};

export default OtherAuctionAlert;
