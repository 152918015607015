import React from "react";
import { Link } from "gatsby";

const baseLink = ({ stateFipsCode, countyFipsCode, cityFipsCode }) => {
	let lnk = `/auctions?state=${stateFipsCode}`;

	if (countyFipsCode) {
		lnk += `&politicalSubDivision=${countyFipsCode}`;
	}

	if (cityFipsCode) {
		lnk += `&city=${cityFipsCode}`;
	}

	return lnk;
};

export const SellerLink = ({ children, ...props }) => (
	<Link to={baseLink(props)} className="small">
		{children} <i className="fa fa-angle-double-right" />
	</Link>
);

export const AuctionTypeLink = ({ children, type }) => (
	<Link to={`/auctions?saleType=${type}`} className="small">
		{children} <i className="fa fa-angle-double-right" />
	</Link>
);

export const SaleLink = ({ children, type, ...props }) => (
	<Link to={`${baseLink(props)}&saleType=${type}`}>{children}</Link>
);
