import React from "react";
import SectionHead from "./section-head";

const W9 = ({ taxAuthority: { name, w9 } }) => {
	return (
		<>
			<SectionHead id="sh_w9Info">W9 DETAILS</SectionHead>
			<table className="registration_table detailview_table">
				<tbody>
					<tr>
						<td className="td_label">Instructions for Tax Sale purchases:</td>
						<td className="td_description">{w9.processingInstructions}</td>
					</tr>
					<tr>
						<td className="td_label">W9 Form:</td>
						<td className="td_description">
							<i title="PDF" className="prop-details__icon-pdf--small" />
							<a href={w9.w9FormUrl}>W9 Form for {name}</a>
						</td>
					</tr>
					<tr>
						<td className="td_label">Email:</td>
						<td className="td_description">
							<a href={`mailto:${w9.emailLink}`}>{w9.email}</a>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default W9;
