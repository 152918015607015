import React, { Component } from "react";

class ButtonCreator extends Component {
	state = {
		buttonHasPopulated: false
	};

	componentDidMount() {
		this.createButton();
	}

	componentDidUpdate() {
		if (!this.state.buttonHasPopulated) {
			this.createButton();
		}
		this.props.onUpdate && this.props.onUpdate();
	}

	createButton() {
		const { map, google, containerId, position } = this.props;
		const containerDiv = document.getElementById(containerId);

		if (!map || !containerDiv) return null;

		map.controls[google.maps.ControlPosition[position]].push(containerDiv);

		this.setState({ buttonHasPopulated: true });
	}

	render() {
		const { containerId, children, ...props } = this.props;

		const childrenWithProps = React.Children.map(children, child =>
			React.cloneElement(child, props)
		);

		return (
			<div style={{ display: "none" }}>
				<div id={containerId}>{childrenWithProps}</div>
			</div>
		);
	}
}

export default ButtonCreator;
