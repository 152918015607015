import React from "react";
import useSheet from "react-jss";

const styles = theme => {
	const u = theme.spacing.unit;
	return {
		root: {
			composes: "clearfix"
		},
		inner: {
			composes: "dl-horizontal",
			marginBottom: u * 1.5
		},
		divider: {
			margin: [u * 1.5, 0]
		}
	};
};

const styled = useSheet(styles);

// All the nested definition list markup cruft
// we use on the existing auction details layout
const _InfoNugget = ({ label, content, classes, children }) => (
	<li className={classes.root}>
		{children ? (
			children
		) : (
			<dl className={classes.inner}>
				<dt>{label}</dt>
				<dd>{content}</dd>
			</dl>
		)}
	</li>
);

const InfoNugget = styled(_InfoNugget);

const _InfoDivider = ({ classes }) => (
	<InfoNugget>
		<hr className={classes.divider} />
	</InfoNugget>
);

export const InfoDivider = styled(_InfoDivider);

export default InfoNugget;
