import React from "react";
import AmountCell from "../amount-cell";

const C = props => <AmountCell isTotal {...props} />;

const TaxesFooter = ({ onlyShowBalance, taxes }) => {
	const totals = getTotals(taxes, onlyShowBalance);

	return (
		<tfoot>
			<tr className="line-items__totals">
				<td />
				<td className="txt-description">Total</td>
				{totals.map(({ label, value }) => (
					<C key={label} amount={value} />
				))}
			</tr>
		</tfoot>
	);
};

const sumBy = (coll, prop) => coll.reduce((sum, o) => sum + o[prop], 0);

const sumByWithLabel = (coll, prop) => ({
	label: prop,
	value: sumBy(coll, prop)
});

const getTotals = (taxes, onlyShowBalance) => {
	// Don't need to sum all that other stuff if we're not gonna show it!
	return (onlyShowBalance
		? ["balance"]
		: ["balance", "interest", "penalty", "collection", "total"]
	).map(prop => sumByWithLabel(taxes, prop));
};

export default TaxesFooter;
