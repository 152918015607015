import React from "react";
import Info from "../info-nugget";

const CostsInfo = ({ isAdjudicationSale, isTaxSale }) => (
	<ul className="list-unstyled">
		<Info
			label={<span className="required">Note:</span>}
			content={
				<div className="text-justify">
					The starting bid is an estimate based on projected costs and will
					change before the auction starts.{" "}
					{isAdjudicationSale ? (
						<>
							After a deposit is placed, research and noticing will occur and
							actual costs be added to this auction.
						</>
					) : null}
					{isTaxSale ? (
						<>
							As research and noticing occur, actual costs will be added to this
							auction.
						</>
					) : null}{" "}
					The actual costs, once known, will determine the starting bid price.
				</div>
			}
		/>
	</ul>
);

export default CostsInfo;
