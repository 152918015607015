import React from "react";
import { WaitingIndicator } from "@civicsource/ui";
import { formatNumber, formatMoney } from "accounting";
import TextPlaceholder from "../../../text-placeholder";
import useCostData from "./data";

const Cost = ({ quantity, description, amount }) => {
	let amountText = formatMoney(amount);

	return (
		<tr>
			<td className="text-right">{formatNumber(quantity)}</td>
			<td>{description}</td>
			<td className="text-right">{amountText}</td>
		</tr>
	);
};

const CostViewer = ({ lotNumber }) => {
	const { isLoading, isLoaded, isError, costs, total } = useCostData(lotNumber);

	const costsHeader = (
		<thead>
			<tr>
				<th className="text-right">Quantity</th>
				<th>Description</th>
				<th className="text-right">Cost</th>
			</tr>
		</thead>
	);

	if (isLoading) {
		return (
			<table className="table table-condensed">
				{costsHeader}
				<tbody>
					<tr>
						<td className="text-right">
							<TextPlaceholder />
						</td>
						<td>
							<TextPlaceholder likePhrase="Preliminary Property Verification Research" />
						</td>
						<td className="text-right">
							<TextPlaceholder />
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	if (isError) {
		return (
			<WaitingIndicator noSpinner>Problem loading costs.</WaitingIndicator>
		);
	}

	if (!isLoaded || !costs) {
		return null;
	}

	if (!costs.length) {
		return <WaitingIndicator noSpinner>No costs to display.</WaitingIndicator>;
	}

	return (
		<table className="table table-condensed">
			{costsHeader}
			<tbody>
				{costs.map(({ id, ...rest }) => (
					<Cost key={id} {...rest} />
				))}
			</tbody>
			<tfoot>
				<tr>
					<td />
					<td>Total</td>
					<td className="text-right">
						<strong>{formatMoney(total)}</strong>
					</td>
				</tr>
			</tfoot>
		</table>
	);
};

export default CostViewer;
