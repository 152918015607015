import React from "react";
import { Fade, Button } from "react-bootstrap";
import useSheet from "react-jss";
import styles from "./index.styles";
import { BusyButton } from "@civicsource/ui";

const EditingHUD = ({
	isEditing,
	isSaving,
	isSavingError,
	justSaved,
	classes,
	charsLeft,
	onCancelEdit
}) => {
	if (justSaved) return null;

	return (
		<Fade in={isEditing || isSaving}>
			<div className={classes.fadeInner}>
				<small className={classes.charsLeft}>
					{charsLeft} character{charsLeft === 1 ? "" : "s"} left
					{isSavingError ? (
						<span className={classes.problemSaving}>
							<i className="fa fa-lg fa-exclamation-circle" /> Problem saving
						</span>
					) : null}
				</small>
				<div className={classes.buttons}>
					{isSaving ? (
						<BusyButton bsSize="xs">Saving</BusyButton>
					) : (
						<div>
							<Button onClick={onCancelEdit} bsStyle="link" bsSize="xs">
								Don't Save
							</Button>
							<Button type="submit" bsStyle="default" bsSize="xs">
								Save Note
							</Button>
						</div>
					)}
				</div>
			</div>
		</Fade>
	);
};

export default useSheet(styles)(EditingHUD);
