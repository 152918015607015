import React from "react";
import { connect } from "fetch-helpers";
import { connectCurrentUser } from "@civicsource/users";

import { get } from "lodash";

export default ComponentToWrap => {
	const DataMassager = ({ noteSaveResult = {}, ...props }) => {
		const { isFetching, isFetched, error } = noteSaveResult;

		return (
			<ComponentToWrap
				{...props}
				isSaving={!!isFetching}
				justSaved={!!isFetched}
				isSavingError={!!error}
			/>
		);
	};

	const Connected = connect(({ user }) => ({
		saveNote: (lotNumber, note) => ({
			noteSaveResult: {
				url: `/api/auctions/${lotNumber}/note`,
				method: "POST",
				bearerToken: get(user, "token"),
				body: JSON.stringify({ note }),
				reset: 2000
			}
		})
	}))(DataMassager);

	return connectCurrentUser(Connected);
};
