import { connect } from "fetch-helpers";
import { connectCurrentUser } from "@civicsource/users";

import { get } from "lodash";

export default ComponentToWrap =>
	connectCurrentUser(
		connect(({ lotNumber, user }) => ({
			fetchWatchlists: () => ({
				watchlists: {
					url: `/api/auctions/${lotNumber}/watchlists`,
					bearerToken: get(user, "token")
				}
			}),
			addAuctionToWatchlist: name =>
				saveStatus({
					lotNumber,
					token: get(user, "token"),
					name,
					isInList: true,
					onComplete: ({ watchlists }) => {
						const itemIndex = watchlists.findIndex(
							w => w.name.toLowerCase() === name.toLowerCase()
						);

						const isNew = itemIndex < 0;

						let item = watchlists[itemIndex] || { name };
						item = { ...item, isInList: true, isNew };

						watchlists = isNew
							? // Append new watchlists.
							  [...watchlists, item]
							: // Replace existing watchlists at the same index.
							  [
									...watchlists.slice(0, itemIndex),
									item,
									...watchlists.slice(itemIndex + 1)
							  ];

						return { watchlists };
					}
				}),
			removeAuctionFromWatchlist: name =>
				saveStatus({
					lotNumber,
					token: get(user, "token"),
					name,
					isInList: false,
					onComplete: ({ watchlists }) => {
						const idx = watchlists.findIndex(
							w => w.name.toLowerCase() === name.toLowerCase()
						);

						if (idx < 0) return null;

						const item = watchlists[idx];

						watchlists = [
							...watchlists.slice(0, idx),
							{ ...item, isInList: false },
							...watchlists.slice(idx + 1)
						];

						return { watchlists };
					}
				})
		}))(ComponentToWrap)
	);

function saveStatus({ lotNumber, token, name, isInList, onComplete }) {
	return {
		saveStatus: {
			url: `/api/auctions/${lotNumber}/watchlists`,
			method: "POST",
			bearerToken: token,
			body: JSON.stringify({ name, isInList }),
			onComplete
		}
	};
}
