import React from "react";
import { Alert, Button } from "react-bootstrap";
import { withHandlers } from "recompose";
import { TimeDisplay } from "@civicsource/ui";

const PendingBanner = ({ bidderListDeadline, handleClickTerms }) => {
	return (
		<Alert bsStyle="success">
			<div className="text-center">
				<h4>Reminder</h4>
				{bidderListDeadline ? (
					<p>
						You need to comply with the sale terms prior to{" "}
						<TimeDisplay
							longDateFormat="dddd, MMMM Do"
							showYear
							time={bidderListDeadline}
							timeFirst
						/>
						.
					</p>
				) : (
					<p>You need to comply with the sale terms prior to bidding.</p>
				)}
				<p>You'll be notified by email once reviewed by the seller.</p>
				<p>
					<Button bsStyle="default" onClick={handleClickTerms}>
						Sale Terms
					</Button>
				</p>
			</div>
		</Alert>
	);
};

export default withHandlers({
	handleClickTerms: ({ onPrompt }) => e => onPrompt(e, true)
})(PendingBanner);
