import React from "react";
import BidPlace from "../../../bid/place";
import BidSummary from "../../../bid/summary";
import { formatMoney } from "accounting";
import BidderListHelpText from "./bidder-list-help";
import Info from "../info-nugget";
import TextPlaceholder from "../../../text-placeholder";

const Bidding = props => {
	const {
		startDate,
		isAdjoiningLandOwner,
		price,
		isOffline,
		sale,
		lotNumber,
		user,
		bidderListId,
		bidderListActionRequired,
		actionRequired,
		isMoneyAuction,
		auction,
		hasBidderListDeadlinePassed
	} = props;

	let priceLabel = null;
	let winningBidLabel = null;

	const isAuctionInCart =
		auction &&
		auction.isEnded &&
		user &&
		user.currentBid &&
		user.currentBid.isWinning &&
		user.isInCart;
	const canPlaceBids = user && user.canPlaceBids;

	if (!isMoneyAuction) {
		priceLabel = "Price";
		winningBidLabel = "Winning Bid";
	} else if (auction && (auction.isOpen || auction.isEnded)) {
		priceLabel = "Winning Bid";
		winningBidLabel = "Winning Bid";
	} else {
		priceLabel = "Starting Bid";
		winningBidLabel = "Starting Bid";
	}

	const bidderListStatusVerbose = (
		<BidderListHelpText
			{...{ bidderListId, hasBidderListDeadlinePassed, user }}
		/>
	);

	return (
		<>
			{!startDate || (!isMoneyAuction && !isAdjoiningLandOwner) ? (
				<Info
					label={<>{priceLabel}:</>}
					content={
						<span className={isMoneyAuction ? "h4" : ""}>
							{formatMoney(price)}
						</span>
					}
				/>
			) : null}

			{isOffline || isAdjoiningLandOwner ? (
				<Info
					label={<div className="form-control-static">Bid:</div>}
					content={
						<div className="form-control-static">
							<b>
								{isAdjoiningLandOwner
									? "Auction canceled due to sale to adjoining landowner"
									: isOffline
									? startDate
										? `Auction held at ${sale.location}`
										: "Auction location "
									: null}
							</b>
						</div>
					}
				/>
			) : null}

			{startDate ? (
				<>
					<Info
						label={
							!isOffline || isAdjoiningLandOwner ? (
								<div className="prop-details__bid-summary-label">
									{winningBidLabel}:
								</div>
							) : null
						}
						content={
							!isOffline || isAdjoiningLandOwner ? (
								<div className="prop-details__bid-summary">
									<BidSummary
										showLoading
										amountPlaceholder={
											<TextPlaceholder likePhrase="$4000.00" />
										}
										countPlaceholder={""}
										auction={props.auction}
									/>
								</div>
							) : null
						}
					/>
					{!isOffline ? (
						<Info
							label={
								!user || canPlaceBids ? (
									<div
										className={
											!user || canPlaceBids ? "form-control-static" : ""
										}
									>
										Bid:
									</div>
								) : null
							}
							content={
								<>
									{!user || canPlaceBids ? (
										<div style={{ maxWidth: "30em" }}>
											<BidPlace
												lotNumber={lotNumber}
												{...props}
												showSignInButton
											/>
										</div>
									) : null}
									{!canPlaceBids &&
									bidderListId &&
									!bidderListActionRequired ? (
										<div>{bidderListStatusVerbose}</div>
									) : null}
									{!canPlaceBids && actionRequired ? (
										<div>
											Please see the additional bidding requirements above.
										</div>
									) : null}
								</>
							}
						/>
					) : null}

					{isAuctionInCart ? (
						<Info
							content={
								<a
									className="btn btn-primary"
									href="/accountdetails/shoppingcart"
								>
									Pay Now
								</a>
							}
						/>
					) : null}
				</>
			) : null}
		</>
	);
};

export default Bidding;
