import React from "react";
import { get } from "lodash";
import connect from "./connect";

const BankAccount = props => {
	const { bankAccounts, handleSelectBankAccount } = props;
	const accounts = get(bankAccounts, "data");

	return accounts && accounts.length ? (
		<select
			className="form-control"
			id="bank-account-chooser"
			name="bankAccounts"
			onChange={handleSelectBankAccount}
		>
			<option value="">Choose a bank account…</option>
			{accounts.map(a => (
				<option key={a.display} name={a.id} value={a.id}>
					{a.display}
				</option>
			))}
		</select>
	) : (
		<div className="page-alerts__container">
			<div className="alert alert-warning text-center">
				<h4>Bank Account Required</h4>
				<p>
					In order to make a purchase, please{" "}
					<a href="/account/bankaccounts">add a bank account</a>.
				</p>
			</div>
		</div>
	);
};

export default connect(BankAccount);
