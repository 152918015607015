import React from "react";
import { get } from "lodash";
import useSheet from "react-jss";
import WatchlistAdder from "../../../watchlist-adder";
import AuctionIcon, { SALE_TYPES } from "../../../info-block/icon";
import TextPlaceholder from "../../../text-placeholder";
import Info from "../info-nugget";

const Address = ({
	isFetched,
	isAdjudicationSale,
	isTaxSale,
	isSecondaryMarket,
	sale,
	lotNumber,
	taxAuthorityDisplayName,
	address,
	user,
	classes
}) => {
	const isOffline = sale ? sale.isOffline : false;

	let detailsText = "";

	if (isAdjudicationSale && !isOffline) {
		detailsText = "Adjudicated Property Sale";
	} else if (isAdjudicationSale && isOffline) {
		detailsText = "Offline Adjudicated Property Sale";
	} else if (isTaxSale && !isOffline) {
		detailsText = "Tax Sale";
	} else if (isTaxSale && isOffline) {
		detailsText = "Offline Tax Sale";
	} else if (isSecondaryMarket) {
		detailsText = "Lien Sale";
	}

	let auctionIcon = isFetched ? (
		<AuctionIcon
			style={{
				position: "absolute",
				left: 16,
				top: 12
			}}
			isDetailsIcon
			title={detailsText}
			auctionType={
				isAdjudicationSale
					? SALE_TYPES.ADJ
					: isTaxSale
					? SALE_TYPES.TAX
					: isSecondaryMarket
					? SALE_TYPES.LIEN
					: ""
			}
		/>
	) : null;

	return (
		<Info
			label={auctionIcon}
			content={
				<div className={classes.identifiersContainer}>
					<h1 className={classes.address}>
						{isFetched ? (
							get(address, "address1") || "Unspecified"
						) : (
							<TextPlaceholder likePhrase="2700 Chartres St" />
						)}
					</h1>
					{user && user.token ? (
						// HACK: WatchlistAdder's connect throws an exception
						// if you only truthy check user for some reason
						<div className={classes.watchlistHolder}>
							<WatchlistAdder
								lotNumber={lotNumber}
								isFavorite={user.isFavorite}
							/>
						</div>
					) : null}
					<br />
					<h2 className={classes.locality}>
						{isFetched ? (
							// eslint-disable-next-line prettier/prettier
							`${get(address, "city") || taxAuthorityDisplayName}, ${get(address, "state.value")} ${get(address, "postalCode")}`
						) : (
							<TextPlaceholder likePhrase="New Orleans, Louisiana 70117" />
						)}
					</h2>
				</div>
			}
		/>
	);
};

const styles = {
	icon: {
		position: "absolute",
		left: 0,
		top: "0.25em",
		verticalAlign: "middle",
		fontSize: 42,
		width: "60px !important",
		textAlign: "center"
	},
	identifiersContainer: {
		marginLeft: 0
	},
	auctionIdentifier: {
		display: "inline-block",
		fontFamily: `"Gill Sans MT", "Century Gothic", Helvetica, Verdana, sans-serif`
	},
	address: {
		composes: "$auctionIdentifier",
		fontSize: 24,
		fontWeight: 700,
		textTransform: "uppercase",
		marginTop: 10,
		marginBottom: 0
	},
	locality: {
		composes: "$auctionIdentifier",
		fontSize: 20,
		fontWeight: 400,
		color: "#777",
		marginTop: 10,
		marginBottom: 0
	},
	watchlistHolder: { display: "inline-block", marginLeft: 8, fontSize: "16pt" }
};

export default useSheet(styles)(Address);
