import React from "react";
import { Modal, Button, Checkbox } from "react-bootstrap";
import Remarkable from "remarkable";

const md = new Remarkable({
	html: true,
	breaks: true
});

const ApplicationModal = ({
	isModalOpen,
	onCancel,
	onAgree,
	classes,
	terms,
	isTermsAgreementChecked,
	onAgreementCheckChange,
	readOnly
}) => {
	return (
		<Modal bsSize="lg" show={isModalOpen} onHide={onCancel}>
			<Modal.Header closeButton>
				<Modal.Title>Sale Terms</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className={classes.termsContent}>
					{terms && (
						<div
							dangerouslySetInnerHTML={{
								__html: md.render(terms.terms)
							}}
						/>
					)}
					<hr />
					{!readOnly && (
						<Checkbox
							checked={isTermsAgreementChecked}
							onChange={onAgreementCheckChange}
						>
							{" "}
							I understand and agree.
						</Checkbox>
					)}
				</div>
			</Modal.Body>
			{!readOnly && (
				<Modal.Footer>
					<Button
						onClick={onAgree}
						bsStyle="primary"
						disabled={!isTermsAgreementChecked}
					>
						Get Started
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default ApplicationModal;
