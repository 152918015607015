import React, { Component } from "react";
import { ViewPager, Frame, Track, View } from "@azangru/react-view-pager";
import useSheet from "react-jss";

// TODO: Port the swipe/link disable logic here to /src/carousel

class ImageThumbnails extends Component {
	_track = null;

	// using a raw instance property instead of state here
	// so we don't trigger rerenders from setting this flag:
	// we don't need to because this should only ever be read
	// from the click handler itself and not the render method.
	_enableLinks = true;

	prev = () => {
		this._track.prev();
	};

	next = () => {
		this._track.next();
	};

	handleSwipeMove = () => {
		this._enableLinks = false;
	};

	handleSwipeEnd = () => {
		// mouseDown then mouseMove = swipeMove
		// subsequent mouseUp = swipeEnd and click, order not guaranteed
		// push swipeEnd's side effects to the end of the queue in the event loop
		// so it's set after the mouseup from the swipe, and clicks are only
		// enabled from subsequent mouseups
		setTimeout(() => {
			this._enableLinks = true;
		}, 0);
	};

	handleClickImage = id => e => {
		e && e.preventDefault && e.preventDefault();
		if (this._enableLinks) {
			this.props.onSelectImage(id);
		}
	};

	render() {
		const { images, classes } = this.props;

		return (
			<div className={classes.root}>
				<ViewPager>
					<Frame>
						<Track
							viewsToShow={3}
							ref={el => (this._track = el)}
							onSwipeMove={this.handleSwipeMove}
							onSwipeEnd={this.handleSwipeEnd}
							onViewChange={this.handleViewChange}
						>
							{images.map(({ id, docTypeName, thumbnailUrl, fullSizeUrl }) => {
								return (
									<View key={id}>
										<div className={classes.imgContainer}>
											<a
												onClick={this.handleClickImage(id)}
												href={fullSizeUrl}
												title={docTypeName}
												className={classes.img}
												style={{
													backgroundImage: `url(${thumbnailUrl})`
												}}
											/>
										</div>
									</View>
								);
							})}
						</Track>
					</Frame>
				</ViewPager>
				<button
					className={classes.prev}
					onClick={this.prev}
					title="Previous image"
				>
					<i className="fa fa-chevron-left" />
				</button>
				<button
					disabled={images.length <= 3}
					className={classes.next}
					onClick={this.next}
					title="Next image"
				>
					<i className="fa fa-chevron-right" />
				</button>
			</div>
		);
	}
}

const styles = {
	root: {
		position: "relative"
	},
	imgContainer: {
		background: "#666",
		margin: 8
	},
	img: {
		display: "block",
		width: "100%",
		paddingTop: "60%",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "50% 50%",
		opacity: 0.85,
		transition: "100ms",
		"&:hover, &:focus": {
			opacity: 1
		}
	},
	button: {
		"& > .fa": {
			fontSize: 48,
			lineHeight: 1
		},
		background: "none",
		border: 0,
		padding: 12,
		position: "absolute",
		marginTop: -36,
		top: "50%",
		color: "#fff",
		textShadow: "0 0 8px rgba(0,0,0,0.5)",
		opacity: 0.75,
		"&:hover, &:focus": { opacity: 1 },
		"&[disabled]": {
			opacity: 0.5
		}
	},
	prev: {
		composes: "$button",
		left: 0
	},
	next: {
		composes: "$button",
		right: 0
	}
};

export default useSheet(styles)(ImageThumbnails);
