import React, { Component } from "react";
import { WaitingIndicator } from "@civicsource/ui";
import Thumbnails from "./thumbnails";
import Lightbox from "./lightbox";

const URL = `/api/documents`;

const getThumbnailUrl = (id, taxAuthority) =>
	`${URL}/${id}/400x300.jpg?taxAuthority=${taxAuthority}`;
const getJumboUrl = (id, taxAuthority) =>
	`${URL}/${id}/1600x1200.jpg?taxAuthority=${taxAuthority}`;

class Images extends Component {
	state = {
		activeImage: null
	};

	componentDidMount() {
		document.body.addEventListener("keyup", this.handleKeyPress);
	}

	componentWillUnmount() {
		document.body.removeEventListener("keyup", this.handleKeyPress);
	}

	onPrev = () => {
		this.setState(({ activeImage }, { body: images }) => {
			const idx = images.findIndex(img => img.id === activeImage);
			if (idx === 0) return { activeImage: images[images.length - 1].id };
			return { activeImage: images[idx - 1].id };
		});
	};

	onNext = () => {
		this.setState(({ activeImage }, { body: images }) => {
			const idx = images.findIndex(img => img.id === activeImage);
			if (idx === images.length - 1) return { activeImage: images[0].id };
			return { activeImage: images[idx + 1].id };
		});
	};

	handleKeyPress = e => {
		if (this.state.activeImage) {
			if (e.which === 37) this.onPrev();
			if (e.which === 39) this.onNext();
		}
	};

	onSelectImage = id => {
		this.setState({ activeImage: id });
	};

	onCloseLightbox = () => {
		this.setState({ activeImage: null });
	};

	render() {
		const { body: images, isLoading, isError } = this.props;
		if (isLoading) {
			return <WaitingIndicator decorate>Loading images</WaitingIndicator>;
		}

		if (isError) {
			return (
				<div className="msg-empty">
					<p>Problem loading images.</p>
				</div>
			);
		}

		if (images && images.length) {
			const imagesWithUrls = images.map(img => ({
				thumbnailUrl: getThumbnailUrl(img.id, img.taxAuthority),
				fullSizeUrl: getJumboUrl(img.id, img.taxAuthority),
				...img
			}));

			return (
				<>
					<Thumbnails
						onSelectImage={this.onSelectImage}
						images={imagesWithUrls}
					/>
					<Lightbox
						images={imagesWithUrls}
						onCloseLightbox={this.onCloseLightbox}
						onPrev={this.onPrev}
						onNext={this.onNext}
						{...this.state}
					/>
				</>
			);
		}
		return (
			<div className="msg-empty">
				<p>No images available.</p>
			</div>
		);
	}
}

export default Images;
