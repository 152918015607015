import React from "react";
import { get } from "lodash";
import moment from "moment";
import "../../../layout/less/uploaded-docs.less";
import "../../../layout/less/property-details.less";
import Info from "../info-nugget";
import SectionHead from "../section-head";
import AdjCert from "./adj-cert";
import TextPlaceholder from "../../../text-placeholder";

const PropertyDetails = ({
	id,
	taxAuthority,
	accountNumber,
	alternateAccountNumber,
	address,
	description,
	legalDescription,
	isAdjudicationSale,
	adjudicationDate,
	owner,
	isFetched
}) => {
	const note = (
		<Info
			label={<span className="required">Note:</span>}
			content={
				<div className="text-justify">
					The data contained on this page is intended for information purposes
					only, and it is based on the best information available at the posting
					and is not warranted. The data may not reflect the most current
					records.
				</div>
			}
		/>
	);

	return (
		<div>
			<SectionHead id="sh_propertydetails">Property Details</SectionHead>
			<ul
				className="col-md-12 small list-unstyled"
				style={{ paddingTop: "1em" }}
			>
				<>
					<Info
						label={`${taxAuthority.referenceNumberName}:`}
						content={
							isFetched ? (
								accountNumber
							) : (
								<TextPlaceholder likePhrase="123456789" />
							)
						}
					/>

					{!isFetched ||
					(isFetched &&
						alternateAccountNumber &&
						alternateAccountNumber !== accountNumber) ? (
						<Info
							label="Alternate Property ID:"
							content={
								isFetched ? (
									alternateAccountNumber
								) : (
									<TextPlaceholder likePhrase="123456789" />
								)
							}
						/>
					) : null}

					{!isFetched || (isFetched && address && address.address1) ? (
						<Info
							label="Address:"
							content={
								isFetched ? (
									address.address1
								) : (
									<TextPlaceholder likePhrase="123 MAIN ST" />
								)
							}
						/>
					) : null}
					{description ? (
						<Info label="Description:" content={description} />
					) : null}
					{!isFetched || (isFetched && legalDescription) ? (
						<Info
							label="Legal Description:"
							content={
								isFetched ? (
									legalDescription
								) : (
									<TextPlaceholder likePhrase="Subdivision Name: MAIN STREET ADDL ADDITION Lot Number: 1&1 PT 1 Frontage: 69 Depth: 420" />
								)
							}
						/>
					) : null}
					{isAdjudicationSale && adjudicationDate ? (
						<Info
							label="Adjudicated On:"
							content={
								<>
									<time>{moment(adjudicationDate).format("L")}</time>
									<AdjCert {...{ id, taxAuthority, isAdjudicationSale }} />
								</>
							}
						/>
					) : null}

					{!isFetched || (isFetched && get(owner, "name")) ? (
						<Info
							label="Owner Name:"
							content={
								isFetched ? (
									owner.name
								) : (
									<TextPlaceholder likePhrase="JOHN SMITH" />
								)
							}
						/>
					) : null}
					{note}
				</>
			</ul>
		</div>
	);
};

export default PropertyDetails;
