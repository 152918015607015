import React from "react";
import { groupBy } from "lodash";
import C from "../amount-cell";
import TextPlaceholder from "../../../../text-placeholder";
import SectionHead from "../../section-head";
import LineItemsContainer from "../../line-items/container";

const assessmentsByYear = assessments => {
	var groups = groupBy(assessments, function(assessment) {
			return assessment.year;
		}),
		results = [];

	for (var year in groups) {
		results.push({
			year: year,
			assessments: groups[year]
		});
	}

	return results;
};

const Assessments = props => {
	const { isLoading, isFetched, assessments } = props;

	return (
		<LineItemsContainer>
			<SectionHead>Assessment</SectionHead>

			{isLoading || !isFetched ? (
				<table className="line-items__table">
					<thead>
						<tr>
							<th className="txt-date">Year</th>
							<th className="txt-description">Type</th>
							<th className="txt-number">Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row" className="txt-date">
								<TextPlaceholder />
							</th>
							<td>
								<TextPlaceholder />
							</td>
							<td className="txt-number">
								<TextPlaceholder />
							</td>
						</tr>
					</tbody>
				</table>
			) : (
				<>
					{isFetched && !isLoading && (!assessments || !assessments.length) ? (
						<div className="msg-empty">
							<p>No data is available.</p>
						</div>
					) : null}

					{assessments && assessments.length ? (
						<table className="line-items__table">
							<thead>
								<tr>
									<th className="txt-date">Year</th>
									<th className="txt-description">Type</th>
									<th className="txt-number">Amount</th>
								</tr>
							</thead>
							{assessmentsByYear(assessments).map(
								({ year, assessments: asses }) => (
									<tbody key={year}>
										{asses
											? asses.map(({ type, year, amount }, i) => (
													<tr key={`${year}${type.name}`}>
														{i ? null : (
															<th
																scope="row"
																className="txt-date"
																rowSpan={asses.length}
															>
																{year}
															</th>
														)}
														<td className="txt-description">{type.name}</td>
														<C {...{ amount }} />
													</tr>
											  ))
											: null}
									</tbody>
								)
							)}
						</table>
					) : null}
				</>
			)}
		</LineItemsContainer>
	);
};

export default Assessments;
