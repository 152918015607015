import React from "react";

const Nevermind = ({ handleClick }) => {
	return (
		<>
			<a
				href="#"
				className="btn btn-link place-bid__cancel-adjoining"
				onClick={handleClick}
			>
				Never mind
				<br />
				<small>I'm not an adjoining landowner</small>
			</a>
			<br />
		</>
	);
};

export default Nevermind;
