import React, { Component } from "react";
import { formatMoney } from "accounting";
import { every } from "lodash";
import Verify from "./adjoining-landowner-verify";
import CartDepositButton from "./components/cart-deposit-button";
import Modal from "./components/modal";
import { getDefaultCaveats } from "../caveats";
import { checkStatus } from "fetch-helpers";
import "./cart-make-deposit.less";

class MakeDeposit extends Component {
	state = {
		address1: null,
		address2: null,
		city: null,
		state: null,
		postalCode: null,
		showDepositModal: false,
		showPopover: false,
		isDepositPlacing: false,
		isDepositPlaceError: false,
		selectedBankAccount: null,
		isAddressValid: false,
		target: null,
		caveatItems: [],
		caveatsChecked: []
	};

	componentDidMount() {
		const { isMaybeVacant, isMayNotIncludeLand } = getDefaultCaveats(
			this.props.lineItems
		);
		let caveatItems = [
			{
				caption: "The starting price is subject to change.",
				onChange: () => this.handleToggleCaveat(0)
			}
		];

		let index = 1;
		if (isMayNotIncludeLand) {
			caveatItems.push({
				caption: "Based on the assessment, this auction may not include land.",
				onChange: () => this.handleToggleCaveat(index)
			});
			index++;
		}

		if (isMaybeVacant) {
			caveatItems.push({
				caption: "Based on the assessment, this land may be a vacant lot.",
				onChange: () => this.handleToggleCaveat(index)
			});
			index++;
		}

		caveatItems = [
			...caveatItems,
			...this.props.caveats.map((c, i) => ({
				caption: c,
				onChange: () => this.handleToggleCaveat(index + i)
			}))
		];
		this.setState({
			caveatItems,
			caveatsChecked: Array(caveatItems.length).fill(false)
		});
	}

	handleAddress1Change = e => this.setState({ address1: e.target.value });
	handleAddress2Change = e => this.setState({ address2: e.target.value });
	handleCityChange = e => this.setState({ city: e.target.value });
	handleStateChange = e => this.setState({ state: e.target.value });
	handlePostalCodeChange = e => this.setState({ postalCode: e.target.value });

	handleSelectBankAccount = e => {
		this.setState({ selectedBankAccount: e.target.value });
	};

	handleToggleCaveat = id => {
		const newState = [...this.state.caveatsChecked];
		newState[id] = !newState[id];
		this.setState({ caveatsChecked: newState });
	};

	handleOpenDepositModal = () => this.setState({ showDepositModal: true });

	handleCloseDepositModal = () =>
		this.setState(state => ({
			showDepositModal: false,
			selectedBankAccount: null,
			caveatsChecked: Array(state.caveatItems.length).fill(false)
		}));

	handleTogglePopover = ({ target }) => {
		this.setState(s => ({ target, showPopover: !s.showPopover }));
	};

	handlePlaceDeposit = async () => {
		const { taxAuthority, user, id, onDepositPlaced } = this.props;
		const {
			address1,
			address2,
			city,
			state,
			postalCode,
			selectedBankAccount,
			caveatItems
		} = this.state;

		const url = `/api/auctions/${taxAuthority.code}/${id}/deposit`;

		this.setState({ isDepositPlacing: true, isDepositPlaceError: false });
		try {
			let response = await fetch(url, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${user.token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					auctionId: id,
					ta: taxAuthority.code,
					isAdjoiningLandowner: every(
						[address1, city, state, postalCode],
						Boolean
					),
					bankAccountId: selectedBankAccount,
					adjoiningLandownerAddress: {
						address1,
						address2,
						city,
						state,
						postalCode: postalCode,
						country: "US"
					},
					caveats: caveatItems.map(c => c.caption)
				})
			});

			await checkStatus(response);

			this.setState({
				isDepositPlacing: false,
				isDepositPlaceError: false,
				showDepositModal: false,
				showPopover: false
			});

			onDepositPlaced();
		} catch (e) {
			this.setState({
				isDepositPlaceError: true,
				isDepositPlacing: false,
				showDepositModal: false,
				showPopover: false
			});
		}
	};

	render() {
		const {
			status,
			user,
			auctionId,
			adjoiningLandownerAddress,
			isAdjoiningLandowner,
			depositPrice,
			taxAuthority
		} = this.props;

		const {
			address1,
			city,
			state,
			postalCode,
			caveatItems,
			caveatsChecked,
			selectedBankAccount,
			showDepositModal,
			isDepositPlacing,
			isDepositPlaceError,
			showPopover,
			target
		} = this.state;
		if (status.isCancelled) return null;

		const addressFormHandlers = {
			handleAddress1Change: this.handleAddress1Change,
			handleAddress2Change: this.handleAddress2Change,
			handleCityChange: this.handleCityChange,
			handleStateChange: this.handleStateChange,
			handlePostalCodeChange: this.handlePostalCodeChange
		};

		const incompleteAddress = !every(
			[address1, city, state, postalCode],
			Boolean
		);
		const incompleteDepositForm =
			!selectedBankAccount || !every(caveatsChecked, Boolean);

		const verifyProps = {
			handleTogglePopover: this.handleTogglePopover,
			adjoiningAddress: adjoiningLandownerAddress,
			user,
			auctionId,
			isAdjoiningLandowner,
			taxAuthority,
			depositPrice,
			target,
			showPopover
		};

		const isAuthenticated = (
			<>
				<div>
					<Verify
						{...verifyProps}
						onSubmit={this.onSubmit}
						handleOpenDepositModal={this.handleOpenDepositModal}
						disableCartDepositButton={incompleteAddress}
						{...addressFormHandlers}
					/>
					<div className="row">
						<CartDepositButton
							{...this.props}
							isDepositPlacing={isDepositPlacing}
							onClick={this.handleOpenDepositModal}
						/>
					</div>
				</div>
				<div style={{ display: !isDepositPlaceError ? "none" : undefined }}>
					<div className="error">
						There was an error placing the deposit. Please refresh the page and
						try again.{" "}
					</div>
				</div>
			</>
		);

		const notAuthenticated = (
			<>
				<Verify {...verifyProps} />
				<a
					className="btn btn-primary long place-bid__action place-bid__bid-button place-deposit__sign-in"
					href={`/login?returnUrl=${window.location.pathname}`}
				>
					Place {formatMoney(depositPrice, "$", 0)} Deposit to Start Auction
				</a>
			</>
		);

		return (
			<>
				<Modal
					{...this.props}
					show={showDepositModal}
					caveatItems={caveatItems}
					disableCartDepositButton={incompleteDepositForm}
					handleSelectBankAccount={this.handleSelectBankAccount}
					handleClose={this.handleCloseDepositModal}
					handlePlaceDeposit={this.handlePlaceDeposit}
					handleCheckCaveat={this.handleCheckCaveat}
					handleUncheckCaveat={this.handleUncheckCaveat}
					{...{ isDepositPlacing, isDepositPlaceError }}
				/>
				{user ? isAuthenticated : notAuthenticated}
			</>
		);
	}
}

export default MakeDeposit;
