import React from "react";
import useSheet from "react-jss";

const styles = {
	cell: {
		verticalAlign: "top"
	},
	nameInner: {
		maxWidth: "45em"
	},
	addressComponent: {
		display: "inline-block",
		marginRight: "0.5em",
		"&:last-child": {
			marginRight: 0
		}
	}
};

const InterestedParty = ({ displayName, address, classes }) => (
	<tr>
		<td className={classes.cell}>
			<div className={classes.nameInner}>{displayName}</div>
		</td>
		<td className={classes.cell}>
			<div className={classes.addressInner}>
				{address
					.trim()
					.split("\n")
					.map(c => (
						<div key={c} className={classes.addressComponent}>
							{c}
						</div>
					))}
			</div>
		</td>
	</tr>
);

export default useSheet(styles)(InterestedParty);
