import React from "react";
import { Alert, Button } from "react-bootstrap";
import { BusyButton, TimeDisplay } from "@civicsource/ui";
import { withHandlers } from "recompose";
import moment from "moment";

const ApplyBanner = ({
	bidderListDeadline,
	handleApply,
	lotNumber,
	user,
	isAgreeingToTerms,
	taxAuthority,
	startDate
}) => {
	const isGCM = taxAuthority && taxAuthority.code === "GCM";
	const isSCM = taxAuthority && taxAuthority.code === "SCM";

	return (
		<Alert bsStyle="danger">
			<div className="text-center">
				<h4>Action</h4>
				{isGCM ? (
					<p>
						Welcome! Please note you must sign up as a bidder with the Greene
						County Collector’s Office online from August 1-22.
						<br />
						The sale will be held{" "}
						{moment(startDate).format("[at] H a [on] dddd, MMMM DD")} in room
						212 of the Historic County Courthouse at 940 N Boonville Ave,
						Springfield, MO 65802.
					</p>
				) : bidderListDeadline ? (
					<p>
						The seller requires additional steps to participate be met prior to{" "}
						<TimeDisplay
							longDateFormat="dddd, MMMM Do"
							showYear
							time={bidderListDeadline}
							timeFirst
						/>
						.
					</p>
				) : (
					<p>The seller requires additional steps to participate be met.</p>
				)}
				{isGCM || isSCM ? null : user && user.token ? (
					isAgreeingToTerms ? (
						<BusyButton>Loading...</BusyButton>
					) : (
						<Button bsStyle="default" onClick={handleApply}>
							Sale Terms
						</Button>
					)
				) : (
					<Button bsStyle="default" href={`/login?returnUrl=/${lotNumber}`}>
						Sign In
					</Button>
				)}
			</div>
		</Alert>
	);
};

export default withHandlers({
	handleApply: ({ onPrompt }) => e => onPrompt(e, false)
})(ApplyBanner);
