import React, { Component } from "react";
import { get } from "lodash";
import { Map, GoogleApiWrapper } from "google-maps-react";
import TaxSaleMarker from "./map-markers_tax-sale-2.png";
import AdjSaleMarker from "./map-markers_adj-sale-2.png";
import MapButtons from "./buttons";
import NoMap from "./no-map";
import { useFetch } from "react-fetch-hooks";

import {
	COLOR_BRAND_BLUE,
	COLOR_BRAND_RED,
	COLOR_BRAND_GREEN
} from "../../../../colors";

const BRAND_COLORS = {
	blue: COLOR_BRAND_BLUE,
	red: COLOR_BRAND_RED,
	green: COLOR_BRAND_GREEN,
	"green-muted": "#85a260",
	"green-dark": "#435130"
};

const useMapData = lotNumber =>
	useFetch({
		url: lotNumber ? `/api/auctions/?lotNumber=${lotNumber}` : null,
		headers: { Accept: "application/vnd.geo+json" }
	});

const MapContainer = ({
	lotNumber,
	google,
	isTaxSale,
	isFetched: isAuctionFetched
}) => {
	const { body: data, isFetched, error } = useMapData(lotNumber);

	if (!isAuctionFetched || !isFetched) {
		return <div style={{ height: 460, width: "100%", background: "#ddd" }} />;
	}

	if (
		error ||
		(isFetched && !get(data, "geometery") && !get(data, "properties"))
	) {
		return <NoMap />;
	}

	const geoJson = { type: "FeatureCollection", features: [{ ...data }] };
	const points = get(data, "geometry.coordinates");

	return (
		<div style={{ height: 460 }}>
			<Map google={google} zoom={14}>
				{points && points.length ? (
					<Polygon geoJson={geoJson} google={google} isTaxSale={isTaxSale} />
				) : null}
				<MapButtons google={google} />
			</Map>
		</div>
	);
};

class Polygon extends Component {
	state = {
		polygonHasPopulated: false
	};

	componentDidMount() {
		this.createPolygon();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return !get(nextState, "polygonHasPopulated");
	}

	componentDidUpdate() {
		this.createPolygon();
	}

	createPolygon() {
		const { map, google, geoJson, isTaxSale } = this.props;

		if (!map) return null;

		// https://developers.google.com/maps/documentation/javascript/examples/layer-data-dragndrop
		function loadGeoJsonString(geojson) {
			map.data.addGeoJson(geojson);
			zoom(map);
		}

		function zoom(map) {
			var bounds = new google.maps.LatLngBounds();
			map.data.forEach(function(feature) {
				processPoints(feature.getGeometry(), bounds.extend, bounds);
			});
			map.fitBounds(bounds);
		}

		function processPoints(geometry, callback, thisArg) {
			if (geometry instanceof google.maps.LatLng) {
				callback.call(thisArg, geometry);
			} else if (geometry instanceof google.maps.Data.Point) {
				callback.call(thisArg, geometry.get());
			} else {
				geometry.getArray().forEach(function(g) {
					processPoints(g, callback, thisArg);
				});
			}
		}

		loadGeoJsonString(geoJson);

		const img = new Image();

		const resizeImage = () => {
			const center = map.getCenter();

			const image = imageToDataUri(img, 6, 6);
			const marker = new google.maps.Marker({
				position: new google.maps.LatLng(center.lat(), center.lng()),
				icon: image
			});

			marker.setMap(map);
		};

		const loop = () => {
			// sometimes the lat/lng gets stuck in san fransisco, this prevents that
			try {
				if (checkLatLng(map)) {
					resizeImage();
				} else {
					setTimeout(loop, 0);
				}
			} catch (e) {
				if (e instanceof RangeError) {
					return;
				}
			}
		};

		img.onload = loop;

		img.src = isTaxSale ? TaxSaleMarker : AdjSaleMarker;

		map.data.setStyle(f => {
			const count = f.getProperty("count"),
				isStateOrCounty = !!f.getProperty("fips"),
				uiState = f.getProperty("ui-state"),
				isHover = uiState === "hover",
				disableInteraction = f.getProperty("disableInteraction");

			return {
				clickable: isStateOrCounty && !disableInteraction,
				fillColor: !isStateOrCounty
					? BRAND_COLORS.blue
					: count
					? BRAND_COLORS.green
					: BRAND_COLORS["green-muted"],
				fillOpacity: !disableInteraction
					? count
						? isHover
							? 0.875
							: Math.min(
									0.375 + count / (this.maxCount ? this.maxCount : 1),
									0.75
							  )
						: isHover
						? 0.325
						: 0.1875
					: 0,
				strokeWeight: disableInteraction ? 4 : 2,
				strokeColor: !disableInteraction ? "#fff" : BRAND_COLORS["green-dark"],
				strokeOpacity: isHover ? 1 : 0.875
			};
		});

		this.setState({ polygonHasPopulated: true });
	}

	render() {
		return null;
	}
}

const imageToDataUri = (img, width, height) => {
	// https://stackoverflow.com/questions/20958078/resize-a-base-64-image-in-javascript-without-using-canvas
	// create an off-screen canvas
	var canvas = document.createElement("canvas"),
		ctx = canvas.getContext("2d");

	// set its dimension to target size
	canvas.width = width;
	canvas.height = height;
	// draw source image into the off-screen canvas:
	ctx.drawImage(img, 0, 0, width, height);

	// encode image to data-uri with base64 version of compressed image
	return canvas.toDataURL();
};

const checkLatLng = map => {
	const center = map.getCenter();
	return center.lat() !== 37.774929 && center.lng() !== -122.41941600000001;
};

export default GoogleApiWrapper({
	apiKey: process.env.GOOGLE_MAPS_API_KEY
})(MapContainer);
