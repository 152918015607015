import React from "react";
import {
	Modal,
	ModalTitle,
	ModalHeader,
	ModalBody,
	ModalFooter
} from "react-bootstrap";
import Body from "./body";
import Submit from "./submit";

const DepositModal = props => {
	const { isAdjoiningLandowner, show, handleClose } = props;
	return (
		<Modal show={show} onHide={handleClose}>
			<ModalHeader closeButton>
				<ModalTitle className="modal-title">
					Place deposit {isAdjoiningLandowner ? "as adjoining landowner" : ""}
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Body {...props} />
			</ModalBody>
			<ModalFooter>
				<Submit {...props} />
			</ModalFooter>
		</Modal>
	);
};

export default DepositModal;
