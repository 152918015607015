import React from "react";
import moment from "moment";

const AuctionDocument = ({ isSmDown, ...doc }) => {
	const timeContents = (
		<time dateTime={moment(doc.createdOn).format()}>
			{moment(doc.createdOn).format("L")}
		</time>
	);

	const badgeContents = doc.mailClass ? (
		<a
			download={doc.name}
			href={doc.downloadUrl}
			className={`auction-doc-badge ${doc.mailClassCssClass}`}
			style={isSmDown ? { whiteSpace: "normal", textAlign: "left" } : {}}
		>
			{doc.mailClass}
		</a>
	) : null;
	const nameContents = (
		<a download={doc.name} href={doc.downloadUrl}>
			{doc.docTypeName}
		</a>
	);

	const iconContents = (
		<a download={doc.name} href={doc.downloadUrl}>
			{doc.isImage ? (
				<img
					className="uploaded-docs__thumbnail uploaded-docs__thumbnail--small"
					alt=""
					src={doc.thumbUrl.small}
				/>
			) : (
				<i
					title={doc.fileExtension}
					className={doc.iconCssClasses}
					style={isSmDown ? { margin: 0 } : {}}
				/>
			)}
		</a>
	);
	return isSmDown ? (
		<div style={{ padding: 8 }}>
			<div style={{ display: "flex", padding: "0 8px" }}>
				<div style={{ opacity: 0.75 }}>{timeContents}</div>
				<div style={{ marginLeft: "auto" }}>{badgeContents}</div>
			</div>
			<div style={{ display: "flex" }}>
				<div style={{ padding: 8 }}>
					<div>{nameContents}</div>
					<div>{doc.recipient}</div>
				</div>
				<div style={{ marginLeft: "auto", padding: 8 }}>{iconContents}</div>
			</div>
			<hr style={{ margin: "8px 0" }} />
		</div>
	) : (
		<tr>
			<td className="txt-date">{timeContents}</td>
			<td className="txt-description">
				{badgeContents} {nameContents}
			</td>
			<td className="txt-description">{doc.recipient}</td>
			<td className="txt-action">{iconContents}</td>
		</tr>
	);
};

export default AuctionDocument;
