import moment from "moment";

const steps = ({
	status,
	hasDeposit,
	timeline,
	startDate,
	auctionType,
	isAdjoiningLandOwner,
	auction
}) => {
	if (!status || !timeline || !auction) {
		return [];
	}
	const brk = " <br class='hidden-lg hidden-md' />";

	const isAdjudicationSale =
		auctionType && auctionType.toLowerCase().includes("adjudicat");

	return [
		{
			name: "Deposit",
			isPending: !hasDeposit && status.isPreResearch,
			isActive:
				(!timeline.activeOn && (hasDeposit || !!timeline.depositedOn)) ||
				status.isPreSale ||
				status.isDuringSale ||
				status.isPostSale,

			text: hasDeposit
				? "Deposit Placed"
				: status.isPreSale || status.isDuringSale || status.isPostSale
				? "Research Queued"
				: "Deposit Not Placed",

			title: ""
		},
		{
			name: "Researching & Notification",
			isActive:
				(!!timeline.researchCompleteOn && !!timeline.activeOn) ||
				status.isPreSale,

			isPast:
				!!timeline.researchCompleteOn ||
				status.isDuringSale ||
				status.isPostSale,
			text:
				!!timeline.researchCompleteOn || status.name == "ResearchComplete"
					? "Research Complete"
					: timeline.depositPendingOn
					? "Research Pending"
					: !!timeline.activeOn ||
					  status.name == "Researching" ||
					  (status.name == "Public" && status.isPreSale)
					? "Researching"
					: "Research Not Started",
			title: ""
		},
		{
			name: "Auction",
			isPending: !!startDate && !auction.isOpen & !auction.isEnded,

			isActive: auction.isOpen && !auction.isEnded,
			isPast: auction.isEnded,
			text:
				isAdjudicationSale && isAdjoiningLandOwner
					? "Adjoining Landowner"
					: startDate
					? `Auction${brk}${moment(startDate).format("L")}`
					: "Auction Not Scheduled",

			title:
				isAdjudicationSale && isAdjoiningLandOwner
					? "Being purchased by adjoining landowner."
					: ""
		},
		{
			name: "Closing",
			isPending:
				(status.name === "Sold" || status.name === "NotSold") &&
				!timeline.closedOn,

			isActive: !!timeline.closingScheduledDate || !!timeline.closedOn,
			isPast: !!timeline.closedOn || status.name === "Cancelled",
			text: timeline.closedOn
				? `Closing Complete${brk}${moment(timeline.closedOn).format("L")}`
				: timeline.closingScheduledDate
				? `Closing Scheduled${brk}${moment(
						timeline.closingScheduledDate
				  ).format("L")}`
				: status.name === "Sold"
				? "Sold, Closing TBD"
				: status.name === "NotSold"
				? "Not Sold"
				: status.name === "Cancelled"
				? "Canceled"
				: "Closing TBD",
			title: ""
		}
	];
};

export default steps;
