import React from "react";
import CostViewer from "./viewer";
import CostsInfo from "./costs-info";
import SectionHead from "../section-head";
import LineItemsContainer from "../line-items/container";

const Costs = props => {
	const { isAdjudicationSale, isTaxSale, displaySpecialNote } = props;

	return (
		<LineItemsContainer>
			<SectionHead>Costs</SectionHead>
			<div className="auction-costs__container">
				<div className="col-md-12">
					<br />

					<CostsInfo {...{ isAdjudicationSale, isTaxSale }} />
				</div>

				<CostViewer {...props} />
				{displaySpecialNote || isAdjudicationSale ? (
					<div className="line-items__note__container">
						{isAdjudicationSale ? (
							<>
								*{" "}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="/assets/docs/TitleInsurancePolicy.pdf"
								>
									See an example of the title insurance policy
								</a>
							</>
						) : null}
						<br />
						<br />
						{displaySpecialNote ? (
							<>
								<br />
								<h4>Note:</h4>
								<p className="text-justify">
									The Parish of Caddo reserves unto itself all oil, gas and
									other minerals and mineral rights, whatsoever, in, on or under
									the property transferred in this instrument. The Parish of
									Caddo shall exercise the mineral rights herein reserved with
									reasonable regard to the rights of the surface landowner and
									shall use only so much of the land, including the surface, as
									is reasonably necessary to conduct operations. Such exercise
									of mineral rights shall be subject to the provisions of
									Articles 11 and 22 of the Louisiana Mineral Code. The Parish
									of Caddo shall at all times have the right of ingress and
									egress on, over and under the surface of said lands as
									reasonably necessary to the exercise of the mineral servitude
									herein reserved, and such other rights to use the surface that
									are necessary.
								</p>
							</>
						) : null}
					</div>
				) : null}
			</div>
		</LineItemsContainer>
	);
};

export default Costs;
