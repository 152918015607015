import React from "react";
import Taxes from "./taxes";
import Assessments from "./assessments";
import "../../../layout/less/line-items.less";

const LineItems = props => {
	const { data } = props.lineItems || {};

	if (data && data.taxes) {
		data.taxes.forEach(tax => {
			tax.total = tax.balance + tax.interest + tax.penalty + tax.collection;
		});
	}

	return (
		<>
			<Taxes {...props} taxes={data ? data.taxes : []} />
			<Assessments {...props} assessments={data ? data.assessments : null} />
		</>
	);
};

export default LineItems;
