import React from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grow,
	Typography
} from "@material-ui/core";
import useSheet from "react-jss";

const Lightbox = ({
	onCloseLightbox,
	onPrev,
	onNext,
	images,
	activeImage,
	classes
}) => {
	const activeIdx = images.findIndex(im => im.id === activeImage);
	const activeImageObj = images[activeIdx];

	return (
		<Dialog
			onClose={onCloseLightbox}
			open={!!activeImage}
			maxWidth="lg"
			scroll="body"
			TransitionComponent={Grow}
			aria-labelledby="lightbox-image-title"
		>
			<DialogTitle id="lightbox-image-title">
				<div style={{ display: "flex" }}>
					{activeImageObj && activeImageObj.docTypeName}{" "}
					<Typography variant="caption" style={{ marginLeft: "auto" }}>
						Image {activeIdx + 1} of {images.length}
					</Typography>
				</div>
			</DialogTitle>
			<DialogContent>
				<div className={classes.bigImgContainer}>
					<div
						className={classes.bigImg}
						style={{
							backgroundImage: activeImageObj
								? `url(${activeImageObj.fullSizeUrl}), url(${
										activeImageObj.thumbnailUrl
								  })`
								: null
						}}
					/>

					<button
						className={classes.prev}
						onClick={onPrev}
						title="Previous image"
					>
						<i className="fa fa-4x fa-chevron-left" />
					</button>
					<button className={classes.next} onClick={onNext} title="Next image">
						<i className="fa fa-4x fa-chevron-right" />
					</button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

const styles = {
	button: {
		background: "none",
		border: 0,
		position: "absolute",
		top: "50%",
		marginTop: "-1em",
		color: "#fff",
		textShadow: "0 0 8px rgba(0,0,0,0.5)",
		opacity: 0.75,
		"&:hover, &:focus": { opacity: 1 },
		"&[disabled]": {
			opacity: 0.5
		}
	},
	bigImgContainer: {
		padding: 16,
		position: "relative",
		background: "#444"
	},
	bigImg: {
		width: "100%",
		paddingTop: "60%",
		height: "80vh",
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		backgroundPosition: "50% 50%"
	},
	prev: {
		composes: "$button",
		left: 8
	},
	next: {
		composes: "$button",
		right: 8
	}
};

export default useSheet(styles)(Lightbox);
