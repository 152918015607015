import React from "react";
import Disclaimer from "./disclaimer-button";
import FullMap from "./full-map-button";
import useSheet from "react-jss";
import "./map.css";

const MapButtons = props => (
	<>
		<Disclaimer {...props} />
		<FullMap {...props} />
	</>
);

const styles = {
	control: {
		margin: "10px",
		border: "none",
		cursor: "pointer"
	},
	controlUIBase: {
		backgroundColor: "rgb(255, 255, 255)",
		textAlign: "center",
		border: "none",
		transition: ".5s width",
		overflow: "hidden"
	},
	controlUIHidden: {
		composes: "$controlUIBase",
		width: 0
	},
	controlUIVisible: {
		composes: "$controlUIBase",
		width: "190px"
	},
	buttonBase: {
		boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 6px",
		textAlign: "center",
		border: "none",
		width: "100%"
	},
	buttonNormal: {
		composes: "$buttonBase",
		backgroundColor: "rgb(255, 255, 255)"
	},
	buttonHover: {
		composes: "$buttonBase",
		backgroundColor: "rgb(242, 242, 242)"
	},
	controlText: {
		border: "none",
		color: "rgb(25, 25, 25)",
		fontFamily: "Roboto, Arial, sans-serif",
		fontSize: "11px",
		lineHeight: "30px",
		padding: "2px 10px 2px 10px"
	},
	disclaimer: {
		position: "relative",
		background: "rgb(0,0,0)",
		backgroundColor: "rgba(0, 0, 0, .7)",
		color: "rgb(255, 255, 255)",
		border: "none",
		fontFamily: "Roboto, Arial, sans-serif",
		fontSize: "11px",
		paddingLeft: "5px",
		paddingRight: "5px",
		padding: "10px",
		cursor: "initial",
		width: "190px"
	}
};

export default useSheet(styles)(MapButtons);
