import React, { Component } from "react";
import ButtonCreator from "./button-creator";

class FullMap extends Component {
	state = {
		isHoveringButton: false,
		isMiddleClick: false
	};

	handleButtonMouseEnter = () => this.setState({ isHoveringButton: true });
	handleButtonMouseLeave = () => this.setState({ isHoveringButton: false });

	handleMouseDown = (e = {}) => {
		const isMiddleClick = e.button === 1;
		if (isMiddleClick) {
			e.preventDefault();
			this.setState({ isMiddleClick: true });
		}
	};

	handleMouseUp = () => {
		const { map } = this.props;
		const center = map.getCenter();
		const lat = center.lat();
		const lng = center.lng();
		const url = `/map?at=${lat},${lng},${map.zoom}z`;

		if (this.state.isMiddleClick) {
			window.open(url, "_blank");
			this.setState({ isMiddleClick: false });
		}
	};

	handleButtonClick = () => {
		const { map } = this.props;
		const center = map.getCenter();
		const lat = center.lat();
		const lng = center.lng();
		const url = `/map?at=${lat},${lng},${map.zoom}z`;

		window.location.href = url;
	};

	render() {
		const { map, google, classes } = this.props;
		const { isHoveringButton } = this.state;

		return (
			<ButtonCreator
				containerId="full-map-container"
				position="TOP_RIGHT"
				{...{ map, google }}
			>
				<button
					className={
						isHoveringButton ? classes.buttonHover : classes.buttonNormal
					}
					id="controlUI"
					title="View full map"
					onMouseEnter={this.handleButtonMouseEnter}
					onMouseLeave={this.handleButtonMouseLeave}
					onClick={this.handleButtonClick}
					onMouseDown={this.handleMouseDown}
					onMouseUp={this.handleMouseUp}
				>
					<div className={classes.controlText}>
						<i className="fa fa-expand fa-lg" aria-hidden="true" />
						&nbsp;&nbsp;&nbsp;View full map
					</div>
				</button>
			</ButtonCreator>
		);
	}
}

export default FullMap;
