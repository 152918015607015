import React from "react";
import {
	Form,
	FormGroup,
	FormControl,
	ControlLabel,
	Col
} from "react-bootstrap";
import states from "./states";

const labelStyles = {
	textAlign: "right",
	marginBottom: 0,
	paddingTop: 7
};

const AddressBuilder = props => {
	const {
		handleAddress1Change,
		handleAddress2Change,
		handleCityChange,
		handleStateChange,
		handlePostalCodeChange
	} = props;

	return (
		<div>
			<Form onSubmit={props.onSubmit}>
				<FormGroup controlId="address1">
					<Col sm={2} style={labelStyles} componentClass={ControlLabel}>
						Street
					</Col>
					<Col sm={10}>
						<FormControl
							style={{ marginBottom: 10 }}
							onChange={handleAddress1Change}
							placeholder="Address Line 1"
						/>
					</Col>
				</FormGroup>

				<FormGroup controlId="address2">
					<Col sm={2} style={labelStyles} componentClass={ControlLabel}>
						Apt/Unit
					</Col>
					<Col sm={10}>
						<FormControl
							style={{ marginBottom: 10 }}
							onChange={handleAddress2Change}
							placeholder="Address Line 2"
						/>
					</Col>
				</FormGroup>

				<FormGroup controlId="city">
					<Col sm={2} style={labelStyles} componentClass={ControlLabel}>
						City
					</Col>
					<Col sm={10}>
						<FormControl
							style={{ marginBottom: 10 }}
							onChange={handleCityChange}
							placeholder="City"
						/>
					</Col>
				</FormGroup>

				<FormGroup controlId="state">
					<Col sm={2} style={labelStyles} componentClass={ControlLabel}>
						State
					</Col>
					<Col sm={4}>
						<FormControl
							style={{ marginBottom: 10 }}
							onChange={handleStateChange}
							componentClass="select"
							placeholder="select"
						>
							{states.map(s => (
								<option key={s.abbreviation} value={s.abbreviation}>
									{s.name}
								</option>
							))}
						</FormControl>
					</Col>
					<Col sm={2} style={labelStyles} componentClass={ControlLabel}>
						ZIP
					</Col>
					<Col sm={4}>
						<FormControl
							name="postal-code"
							style={{ marginBottom: 10 }}
							onChange={handlePostalCodeChange}
							placeholder="Zip Code"
						/>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};

export default AddressBuilder;
