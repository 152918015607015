import React from "react";
import { get } from "lodash";

const BidderListHelpText = ({
	bidderListId,
	hasBidderListDeadlinePassed,
	user
}) => {
	// TODO: Rework logic for early returns instead of mutation
	let result = null;
	if (bidderListId) {
		const status = get(user, "bidderListStatus");

		if (!status && hasBidderListDeadlinePassed) {
			result = <>The deadline for applying to bid on this sale has passed.</>;
		}

		if (!status || !!user) {
			result = <>You must apply for approval before you can bid.</>;
		}

		if (status === "Denied") {
			result = (
				<>
					Your application to bid has been denied:{" "}
					{get(user, "bidderListDenialReason")}
				</>
			);
		}
		if (status === "Approved") {
			result = <>You are approved to bid.</>;
		}
		if (status === "Pending") result = <>Pending approval.</>;
	} else {
		result = <>All registered users can participate.</>;
	}

	return result;
};

export default BidderListHelpText;
