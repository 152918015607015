import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { get } from "lodash";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import connect from "./connect";
import Apply from "./apply";
import Pending from "./pending";
import Error from "./error";
import Modal from "./modal";

class BidderListApplication extends Component {
	state = {
		isModalOpen: false,
		isTermsAgreementChecked: false,
		isAgreeingToTerms: false,
		isAgreeingToTermsError: false,
		terms: get(this.props, "terms.data") || {}
	};

	componentDidUpdate(prevProps) {
		if (!get(prevProps, "terms.data") && get(this.props, "terms.data")) {
			this.setState((_, props) => ({
				terms: props.terms.data
			}));
		}
	}

	onPrompt = (e, readOnly) => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		this.setState({ isModalOpen: true, isModalReadOnly: readOnly });
	};

	onAgreementCheckChange = ({ target: { checked } }) =>
		this.setState({ isTermsAgreementChecked: checked });

	onCancel = () =>
		this.setState({ isModalOpen: false, isTermsAgreementChecked: false });

	onAgree = async () => {
		this.setState({
			isModalOpen: false,
			isAgreeingToTerms: true,
			isAgreeingToTermsError: false
		});
		const { taxAuthority, bidderListId, user, lotNumber, terms } = this.props;

		const url = `${process.env.ADMIN_API_URL}${
			taxAuthority.code
		}/bidderLists/${bidderListId}/users/${user.id}/terms`;

		try {
			let response = await fetch(url, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${user.token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ lotNumber, termsId: terms.data.termsId })
			});

			response = await response.json();

			this.setState({
				isAgreeingToTerms: false,
				terms: response
			});
		} catch (e) {
			this.setState({ isAgreeingToTermsError: true, isAgreeingToTerms: false });
		}
	};

	render() {
		const {
			bidderListActionRequired,
			classes,
			bidderListDeadline,
			lotNumber,
			user,
			taxAuthority,
			startDate
		} = this.props;

		const { terms, isAgreeingToTermsError, isAgreeingToTerms } = this.state;
		const hasAgreed = this.state.terms.hasAgreedToTerms;
		const requiresBidderListTerms = bidderListActionRequired;
		if (
			!this.props.bidderListId ||
			(!requiresBidderListTerms &&
				get(user, "bidderListStatus") != "Pending" &&
				!isAgreeingToTermsError)
		) {
			return null;
		}

		return (
			<>
				<Collapse in={requiresBidderListTerms && !hasAgreed} unmountOnExit>
					<div>
						<Apply
							onPrompt={this.onPrompt}
							{...{
								lotNumber,
								bidderListDeadline,
								user,
								isAgreeingToTerms,
								taxAuthority,
								startDate
							}}
						/>
					</div>
				</Collapse>
				<Collapse
					in={
						(!requiresBidderListTerms &&
							get(user, "bidderListStatus") == "Pending") ||
						hasAgreed
					}
					unmountOnExit
				>
					<div>
						<Pending
							onPrompt={this.onPrompt}
							{...{ bidderListDeadline, terms }}
						/>
					</div>
				</Collapse>
				<Collapse in={isAgreeingToTermsError} unmountOnExit>
					<div>
						<Error />
					</div>
				</Collapse>
				<Modal
					isModalOpen={this.state.isModalOpen}
					onCancel={this.onCancel}
					onAgree={this.onAgree}
					isTermsAgreementChecked={this.state.isTermsAgreementChecked}
					onAgreementCheckChange={this.onAgreementCheckChange}
					readOnly={this.state.isModalReadOnly}
					{...{ classes, terms }}
				/>
			</>
		);
	}
}

const styles = {
	termsContent: {
		padding: [0, bootstrap.gridGutterWidth]
	}
};

export default connect(useSheet(styles)(BidderListApplication));
