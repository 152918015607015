import tc from "tinycolor2";
import { COLOR_BRAND_BLUE, COLOR_BRAND_GREEN } from "../../../colors";

const smMinBreakpoint = "@media only screen and (min-width: 768px)";
const arrowBaseLn = 25;

const timelineBgColor = "#333";
const timelineActiveColor = tc(COLOR_BRAND_BLUE)
	.lighten(15)
	.saturate(5)
	.toString();

const timelinePastColor = tc
	.mix(COLOR_BRAND_BLUE, COLOR_BRAND_GREEN, 10)
	.toString();

const timelinePendingColor = tc
	.mix(COLOR_BRAND_BLUE, timelineBgColor, 35)
	.toString();

export const timelineStyles = {
	container: {
		position: "relative",
		fontSize: 12,
		overflow: "hidden"
	},
	inner: {
		display: "table",
		width: "100%",
		tableLayout: "fixed",
		background: timelineBgColor
	}
};

const makeProgressIndicatorStyles = baseColor => {
	return {
		composes: "$indicator",
		backgroundColor: baseColor,
		backgroundImage: `linear-gradient(to right, ${tc(baseColor)
			.darken(5)
			.toString()} 50%, ${baseColor} 100%)`,
		"&:before, &:after": {
			borderLeftColor: baseColor
		},
		"&:before": {
			borderLeftColor: tc.mix("#fff", baseColor).toString()
		}
	};
};

export const indicatorStyles = {
	indicator: {
		display: "block",
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		transition: ".5s",
		"&:before, &:after": {
			content: "''",
			display: "block",
			position: "absolute",
			height: 0,
			borderWidth: arrowBaseLn,
			borderStyle: "solid",
			borderColor: "transparent",
			right: -(2 * arrowBaseLn) + 1,
			top: "50%",
			marginTop: -arrowBaseLn
		},
		"&:before": {
			marginRight: -1
		}
	},
	past: makeProgressIndicatorStyles(timelinePastColor),
	active: makeProgressIndicatorStyles(timelineActiveColor),
	pending: makeProgressIndicatorStyles(timelinePendingColor),
	placeholder: makeProgressIndicatorStyles(timelineBgColor)
};

export const stepStyles = {
	step: {
		display: "block",
		[smMinBreakpoint]: {
			display: "table-cell"
		},
		verticalAlign: "middle",
		position: "relative",
		padding: [6, 12, 6, 12 + arrowBaseLn],
		whiteSpace: "nowrap",
		textAlign: "center",
		color: tc.mix(COLOR_BRAND_BLUE, "#fff", 85).toString()
	},
	active: {
		composes: "$step",
		background: timelineActiveColor,
		[smMinBreakpoint]: {
			background: "none"
		}
	},
	past: {
		composes: "$step",
		background: timelinePastColor,
		[smMinBreakpoint]: {
			background: "none"
		}
	},
	pending: {
		composes: "$step",
		background: timelinePendingColor,
		[smMinBreakpoint]: {
			background: "none"
		}
	}
};
