import tc from "tinycolor2";
import { bootstrap } from "toetag";

const noteBorder = tc(bootstrap.alertWarningBorder)
	.darken()
	.desaturate(20)
	.toString();

export default {
	"@keyframes fade-out-just-saved": {
		from: { opacity: 1 },
		to: { opacity: 0 }
	},
	fadeInner: {
		position: "relative"
	},
	container: {
		composes: "small",
		transition: "125ms box-shadow",
		cursor: "pointer",
		padding: [
			bootstrap.paddingSmallVertical,
			bootstrap.paddingSmallHorizontal,
			24,
			bootstrap.paddingSmallHorizontal
		],
		border: {
			width: 1,
			style: "solid",
			color: bootstrap.alertWarningBorder,
			radius: bootstrap.borderRadiusSmall
		},
		background: bootstrap.alertWarningBg,
		resize: "none",
		display: "block",
		width: "100%"
	},
	intent: {
		composes: "$container",
		boxShadow: {
			x: 0,
			y: 5,
			blur: 8,
			spread: 0,
			color: tc(bootstrap.alertWarningBorder)
				.darken(30)
				.setAlpha(0.1)
				.toString()
		},
		borderColor: noteBorder,
		background: tc(bootstrap.alertWarningBg)
			.lighten(3)
			.toString()
	},
	editing: {
		extend: "intent",
		background: "#fff",
		cursor: "text"
	},
	outer: {
		position: "relative"
	},
	bottomLeft: {
		position: "absolute",
		bottom: bootstrap.paddingSmallVertical,
		left: bootstrap.paddingSmallHorizontal
	},
	charsLeft: { composes: "$bottomLeft" },
	clickToEdit: {
		composes: "$bottomLeft",
		color: noteBorder,
		cursor: "pointer"
	},
	bottomRight: {
		position: "absolute",
		bottom: bootstrap.paddingSmallVertical,
		right: bootstrap.paddingSmallHorizontal
	},
	buttons: { composes: "$bottomRight" },
	intentIcon: {
		composes: "$bottomRight",
		color: noteBorder,
		cursor: "pointer"
	},
	justSaved: {
		composes: "$intentIcon",
		animation: {
			name: "fade-out-just-saved",
			duration: 2000,
			iterationCount: 1
		}
	},
	problemSaving: {
		composes: "text-danger",
		display: "inline-block",
		paddingLeft: bootstrap.paddingBaseHorizontal
	}
};
