import React, { Component } from "react";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import Scrollable from "../scrollable";
import WatchlistsViewer from "./watchlists-viewer";
import { LinkPseudo } from "@civicsource/ui";
import AdderForm from "./form";
import { some } from "lodash";

import connect from "./connect";

const getPopboxPlacement = () => {
	return global.window &&
		global.window.matchMedia(`screen and (min-width: ${bootstrap.screenSmMax})`)
			.matches
		? "left"
		: "bottom";
};

class WatchlistAdder extends Component {
	state = {
		newWatchlistName: ""
	};

	handleValueChange = ({ target: { value } }) =>
		this.setState({ newWatchlistName: value });

	handleSubmit = e => {
		if (e && e.preventDefault) e.preventDefault();

		const name = this.state.newWatchlistName;
		this.setState({ newWatchlistName: "" });
		this.props.addAuctionToWatchlist(name);
	};

	isFavorite() {
		const { isFavorite, watchlists } = this.props;
		const { data: items } = watchlists || {};

		// if we haven't loaded watchlist data yet, trust the search data
		if (!items) return isFavorite;

		// if we have loaded watchlist data, use that instead to determine favorite status
		return some(items, i => i.isInList);
	}

	render() {
		if (!this.props.user) return null;

		const {
			lotNumber,
			classes,
			isHover,
			isFocus,
			onMouseEnter,
			onMouseLeave,
			onFocus,
			onBlur,
			...props
		} = this.props;

		const popboxTitle = (
			<span className="clearfix">
				<span className="pull-left">Add to Watchlist</span>{" "}
				<span className="pull-right">
					<a href="/accountdetails/watchlists">Edit Watchlists</a>
				</span>
			</span>
		);

		const popbox = (
			<Popover title={popboxTitle} id={`watchlist-popbox--${lotNumber}`}>
				<Scrollable maxHeight="16em" backgroundColor="#fafafa">
					<WatchlistsViewer lotNumber={lotNumber} {...props} />
				</Scrollable>
				<br />
				<AdderForm
					handleSubmit={this.handleSubmit}
					handleValueChange={this.handleValueChange}
					newWatchlistName={this.state.newWatchlistName}
					lotNumber={lotNumber}
					{...props}
				/>
			</Popover>
		);

		return (
			<OverlayTrigger
				shouldUpdatePosition
				overlay={popbox}
				trigger="click"
				rootClose
				placement={getPopboxPlacement()}
			>
				<Button
					title="Add to a watchlist"
					bsClass={classes.btn}
					{...{ onMouseEnter, onMouseLeave, onFocus, onBlur }}
				>
					<i
						className={
							this.isFavorite()
								? classes.iconHas
								: isHover || isFocus
								? classes.iconActive
								: classes.iconInActive
						}
					/>
				</Button>
			</OverlayTrigger>
		);
	}
}

const styles = {
	btn: {
		background: "none",
		border: 0,
		padding: [0, 2]
	},
	icon: {
		color: bootstrap.textMuted,
		"&, &:hover, &:focus": {
			textDecoration: "none"
		}
	},
	iconHas: {
		composes: ["$icon", "fa", "fa-star"],
		color: bootstrap.brandWarning
	},
	iconInActive: {
		composes: ["$icon", "fa", "fa-star-o"]
	},
	iconActive: {
		composes: ["$icon", "fa fa-star-o"],
		color: bootstrap.brandWarning
	}
};

const WatchlistAdderWrapped = props => (
	<LinkPseudo>
		<WatchlistAdder {...props} />
	</LinkPseudo>
);

export default connect(useSheet(styles)(WatchlistAdderWrapped));
