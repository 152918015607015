import React from "react";
import { Button } from "react-bootstrap";
import useSheet from "react-jss";
import { bootstrap } from "toetag";

const NoteIcon = ({ classes, note, onEdit, id }) => {
	return (
		<Button
			bsClass={note ? classes.btnHasNote : classes.btn}
			onClick={onEdit}
			title="Add a note"
			{...{ id }}
		>
			<i className={note ? classes.iconHasNote : classes.icon} />
		</Button>
	);
};

const styles = {
	btn: {
		background: "none",
		border: 0,
		padding: [0, 2],
		"&:hover, &:focus": {
			color: bootstrap.brandWarning
		}
	},
	btnHasNote: {
		composes: "$btn",
		color: bootstrap.brandWarning
	},
	icon: {
		composes: ["fa", "fa-file-o"]
	},
	iconHasNote: {
		composes: ["fa", "fa-file"]
	}
};

export default useSheet(styles)(NoteIcon);
