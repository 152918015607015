import React from "react";
import useSheet from "react-jss";
import tc from "tinycolor2";
import { onlyUpdateForKeys } from "recompose";

const overflowMaskSize = 60;
const overflowShadowSize = 15;
const defaultBackgroundColor = "#fff";
const defaultMaxHeight = "40em";

const makeContainerStyles = ({
	backgroundColor = defaultBackgroundColor,
	shadowColor = tc
		.mix(backgroundColor, "#000", 15)
		.setAlpha(12)
		.toRgbString(),
	maxHeight = defaultMaxHeight
}) => {
	const transparentBackgroundColor = tc(backgroundColor)
		.setAlpha(0)
		.toRgbString();
	const transparentShadowColor = tc(shadowColor)
		.setAlpha(0)
		.toRgbString();
	return {
		container: {
			overflowY: "auto",
			maxHeight,
			background: [
				`linear-gradient(${backgroundColor} 30%, ${transparentBackgroundColor})`,
				`linear-gradient(${transparentBackgroundColor}, ${backgroundColor} 70%) 0 100%`,
				`radial-gradient(farthest-side at 50% 0, ${shadowColor}, ${transparentShadowColor})`,
				`radial-gradient(farthest-side at 50% 100%, ${shadowColor}, ${transparentShadowColor}) 0 100%`
			],
			backgroundColor: backgroundColor,
			backgroundRepeat: "no-repeat",
			backgroundSize: [
				`100% ${overflowMaskSize}px`,
				`100% ${overflowMaskSize}px`,
				`100% ${overflowShadowSize}px`,
				`100% ${overflowShadowSize}px`
			].join(", "),
			backgroundAttachment: ["local", "local", "scroll", "scroll"]
		}
	};
};

const Scrollable = ({
	containerComponent: Container = "div",
	maxHeight = defaultMaxHeight,
	backgroundColor = defaultBackgroundColor,
	children,
	sheet
}) => {
	sheet.addRules(makeContainerStyles({ maxHeight, backgroundColor }));
	return <Container className={sheet.classes.container}>{children}</Container>;
};

const styles = makeContainerStyles({});

export default onlyUpdateForKeys(["backgroundColor", "maxHeight", "children"])(
	useSheet(styles, { link: true, inject: ["sheet"] })(Scrollable)
);
