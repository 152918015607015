import { connect } from "fetch-helpers";

export default function documentsFetcher(ComponentToWrap) {
	return connect(({ id, user }) => ({
		adjCertificateDoc: {
			url: `/api/documents?auctionId=${id}&onlyImages=false&docType=Adjudication%20Certificate`,
			bearerToken: user ? user.token : null,
			keys: ["id"]
		}
	}))(ComponentToWrap);
}
