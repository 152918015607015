import React from "react";
import { formatMoney } from "accounting";

const doNothing = () => null;

const Button = props => {
	const {
		isAdjoiningLandowner,
		depositPrice,
		onClick,
		disableCartDepositButton,
		isDepositPlacing
	} = props;
	return (
		<button
			className="place-bid__action long place-bid__bid-button btn btn-primary"
			style={{ marginBottom: 4, whiteSpace: "normal" }}
			onClick={disableCartDepositButton ? doNothing : onClick}
			disabled={disableCartDepositButton || isDepositPlacing}
		>
			<>
				{isDepositPlacing ? (
					<>Placing Deposit…</>
				) : !isAdjoiningLandowner ? (
					<small>
						Place {formatMoney(depositPrice, "$", 0)} Deposit to Start Auction
					</small>
				) : (
					<>
						Place {formatMoney(depositPrice, "$", 0)} Deposit
						<br />
						<small>to start auction as adjoining landowner</small>{" "}
					</>
				)}
			</>
		</button>
	);
};

export default Button;
