import React, { Component } from "react";
import { Checkbox } from "react-bootstrap";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import { Spinner } from "@civicsource/ui";

class WatchlistsItem extends Component {
	state = { isAdding: false };

	componentDidMount() {
		if (this.props.isNew) {
			this.tryScrollContainer();
		}
	}

	componentDidUpdate({ isInList: prevIsInList }) {
		if (this.props.isInList !== prevIsInList) {
			this.setState({ isAdding: false });
			if (this.props.isInList) this.tryScrollContainer();
		}
	}

	tryScrollContainer = () => {
		if (this._container && this._container.scrollIntoView) {
			this._container.scrollIntoView({ behavior: "smooth" });
		}
	};

	toggle = ({ target: { checked } }) => {
		if (checked) {
			this.setState({ isAdding: true });
		}

		const {
			name,
			addAuctionToWatchlist,
			removeAuctionFromWatchlist
		} = this.props;

		if (checked) {
			addAuctionToWatchlist(name);
		} else {
			removeAuctionFromWatchlist(name);
		}
	};

	render() {
		const { name, isInList, classes } = this.props;
		return (
			<div ref={el => (this._container = el)} className={classes.item}>
				{this.state.isAdding ? (
					<span className={classes.spinner}>
						<Spinner /> {name}
					</span>
				) : (
					<Checkbox onChange={this.toggle} checked={isInList}>
						{name}
					</Checkbox>
				)}
			</div>
		);
	}
}

const styles = {
	item: {
		padding: [0, bootstrap.paddingBaseHorizontal]
	},
	spinner: {
		marginLeft: -2
	}
};

export default useSheet(styles)(WatchlistsItem);
