import React from "react";
import useSheet from "react-jss";
import { timelineStyles } from "./styles";
import TimelineProgressIndicator from "./indicator";
import TimelineStep from "./step";
import massageTimeline from "./massage";
import { compose } from "recompose";

const TimeCube = ({ isFetched, progress, steps, classes }) => {
	return (
		<div className={classes.container}>
			{isFetched ? (
				<>
					<TimelineProgressIndicator {...{ progress }} />
					<div className={classes.inner}>
						{steps.map(s => (
							<TimelineStep key={s.name} {...s} />
						))}
					</div>
				</>
			) : (
				<div>&nbsp;</div>
			)}
		</div>
	);
};

export default compose(
	massageTimeline,
	useSheet(timelineStyles)
)(TimeCube);
