import React from "react";
import SectionHead from "../section-head";
import LineItemsContainer from "../line-items/container";
import connect from "./connect";
import InterestedParty from "./party";

const InterestedParties = props => {
	const { interestedParties, isFetching } = props;
	if (
		isFetching ||
		!interestedParties ||
		!interestedParties.data ||
		(interestedParties.data && !interestedParties.data.length)
	) {
		return null;
	}

	return (
		<LineItemsContainer>
			<SectionHead id="sh_interested_party">Interested Parties</SectionHead>
			<table className="line-items__table" style={{ tableLayout: "fixed" }}>
				<thead>
					<tr>
						<th className="txt-description">Name / Company</th>
						<th className="txt-description">Address</th>
					</tr>
				</thead>
				<tbody>
					{interestedParties.data.map(({ id, ...party }) => (
						<InterestedParty key={id} {...party} />
					))}
				</tbody>
			</table>
		</LineItemsContainer>
	);
};

export default connect(InterestedParties);
