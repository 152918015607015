import React from "react";

const Preqrequisites = props => {
	const { actionRequired, isOffline, user } = props;
	if (isOffline || !actionRequired || !user) return null;

	const items = [
		{
			title: "Add a bank account to your profile",
			link: "/account/bankaccounts",
			show: user.bankAccountRequired,
			severity: "danger"
		},
		{
			title: "Add a driver's license to your profile",
			link: "/account/accountinfo",
			show: user.driversLicenseRequired,
			severity: "danger"
		}
	];

	const shownItems = items.filter(item => item.show);

	if (!shownItems.length) return null;

	return (
		<div className="alert alert-danger">
			<h4 className="text-center">Before you can bid, you must:</h4>
			<div className="text-center">
				{shownItems.map(item => (
					<div key={item.title}>
						<a className="alert-link" href={item.link}>
							{item.title}
						</a>
					</div>
				))}
			</div>
		</div>
	);
};

export default Preqrequisites;
