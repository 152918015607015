import React from "react";
import { get } from "lodash";
import useSheet from "react-jss";
import { indicatorStyles } from "./styles";

const TimelineProgressIndicator = ({ progress, classes }) => {
	return (
		<div className="hidden-xs">
			{["25%", "50%", "75%"].map(marginRight => (
				<div
					key={marginRight}
					className={classes.placeholder}
					style={{ right: 0, marginRight }}
				/>
			))}

			<div
				className={classes.pending}
				style={{
					right: get(progress, "pending.cssRight"),
					opacity: get(progress, "pending.value") ? 1 : 0
				}}
			/>
			<div
				className={classes.active}
				style={{
					right: get(progress, "active.cssRight"),
					opacity: get(progress, "active.value") ? 1 : 0
				}}
			/>
			<div
				className={classes.past}
				style={{
					right: get(progress, "past.cssRight"),
					opacity: get(progress, "past.value") ? 1 : 0
				}}
			/>
			<div
				className={classes.past}
				style={{
					right: get(progress, "past.cssRight"),
					opacity: get(progress, "past") >= 0.5 ? 1 : 0,
					marginRight: "25%"
				}}
			/>
			<div
				className={classes.past}
				style={{
					right: get(progress, "past.cssRight"),
					opacity: get(progress, "past") >= 0.75 ? 1 : 0,
					marginRight: "50%"
				}}
			/>
		</div>
	);
};

export default useSheet(indicatorStyles)(TimelineProgressIndicator);
