import React from "react";
import TextPlaceholder from "../../../text-placeholder";
import Address from "./address";
import Info, { InfoDivider } from "../info-nugget";

const InfoDetailsPlaceholder = ({ lotNumber, isFetched }) => (
	<div className="details-summary__container">
		<Address
			{...{
				lotNumber,
				isFetched
			}}
		/>

		<ul className="prop-details__summary__container">
			<Info
				label={<TextPlaceholder />}
				content={
					<span style={{ fontSize: 18 }}>
						<TextPlaceholder />
					</span>
				}
			/>
			<Info
				label={
					<div className="form-control-static">
						<TextPlaceholder likePhrase="Bid" />
					</div>
				}
				content={
					<div className="form-control-static">
						<TextPlaceholder likePhrase="The auction has not started" />
					</div>
				}
			/>
			<Info
				label={<TextPlaceholder />}
				content={<TextPlaceholder likePhrase="Starts March 6th 8AM" />}
			/>

			<InfoDivider />
			<Info
				label={<TextPlaceholder likePhrase="Deposit" />}
				content={<TextPlaceholder likePhrase="$8500" />}
			/>
			<Info
				label={<TextPlaceholder likePhrase="Seller" />}
				content={<TextPlaceholder likePhrase="East Baton Rouge" />}
			/>
			<Info
				label={<TextPlaceholder likePhrase="Market" />}
				content={<TextPlaceholder likePhrase="Primary" />}
			/>
			<Info
				label={<TextPlaceholder likePhrase="Type" />}
				content={<TextPlaceholder likePhrase="Adjudicated Property Sale" />}
			/>
			<Info
				label={<TextPlaceholder likePhrase="Format" />}
				content={<TextPlaceholder likePhrase="High dollar wins" />}
			/>
		</ul>
	</div>
);

export default InfoDetailsPlaceholder;
