import React from "react";

const SectionHead = ({ children, ...props }) => (
	<div className="section_head" {...props}>
		<h2>{children}</h2>
		<div className="clearer" />
	</div>
);

export default SectionHead;
