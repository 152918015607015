import React, { Component } from "react";
import ButtonCreator from "./button-creator";

class DisclaimerButton extends Component {
	state = {
		isHoveringButton: false,
		isShowingDisclaimer: false
	};

	handleButtonMouseEnter = () => this.setState({ isHoveringButton: true });

	handleButtonMouseLeave = () => this.setState({ isHoveringButton: false });

	handleButtonClick = () =>
		this.setState(state => ({
			isShowingDisclaimer: !state.isShowingDisclaimer
		}));

	render() {
		const { classes, map, google } = this.props;
		const { isHoveringButton, isShowingDisclaimer } = this.state;

		return (
			<ButtonCreator
				containerId="disclaimer-button-container"
				position="LEFT_TOP"
				{...{ map, google }}
			>
				<div className={classes.control} aria-live="polite">
					<button
						className={
							isHoveringButton || isShowingDisclaimer
								? classes.buttonHover
								: classes.buttonNormal
						}
						id="infoBtn"
						title="Disclaimer"
						onMouseEnter={this.handleButtonMouseEnter}
						onMouseLeave={this.handleButtonMouseLeave}
						onClick={this.handleButtonClick}
					>
						<div className={classes.controlText}>
							<i className="fa fa fa-info-circle fa-lg" aria-hidden="true" />
						</div>
					</button>{" "}
					<div
						className={
							isShowingDisclaimer
								? classes.controlUIVisible
								: classes.controlUIHidden
						}
						id="disclaimerContainer"
					>
						<div className={classes.disclaimer}>
							Satellite imagery and map views of properties offered for sale are
							provided for reference purposes only and are not indicative of
							property condition or location.{" "}
						</div>
					</div>
				</div>
			</ButtonCreator>
		);
	}
}

export default DisclaimerButton;
