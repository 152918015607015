import React from "react";
import { stepStyles } from "./styles";
import useSheet from "react-jss";

const TimelineStep = ({
	name,
	isActive,
	isPast,
	isLastActive,
	isPending,
	text,
	title,
	statusIcon,
	classes
}) => {
	if (!name) return null;
	const isActiveOrPast = isActive || isPast;
	return (
		<div
			key={name}
			className={
				isLastActive
					? classes.active
					: isPast
					? classes.past
					: isPending
					? classes.pending
					: isActiveOrPast
					? classes.activeOrPast
					: classes.step
			}
			title={title ? title : ""}
		>
			<div className="clearfix">
				<div className="visible-sm">
					<i className={`fa fa-2x fa-fw ${statusIcon}`} />
				</div>
				<div className="visible-xs-inline-block hidden-sm visible-md-inline-block visible-lg-inline-block">
					<i className={`fa fa-fw ${statusIcon}`} />
				</div>{" "}
				<span dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		</div>
	);
};

export default useSheet(stepStyles)(TimelineStep);
