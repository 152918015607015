import React from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { BusyButton } from "@civicsource/ui";

const AdderForm = ({
	handleSubmit,
	isUpdating,
	handleValueChange,
	newWatchlistName
}) => (
	<Form onSubmit={handleSubmit}>
		<InputGroup bsSize="sm">
			<FormControl
				autoFocus
				onChange={handleValueChange}
				value={newWatchlistName}
				placeholder="New watchlist name"
			/>
			<span className="input-group-btn">
				{isUpdating ? (
					<BusyButton>
						<span className="sr-only">Adding</span>
					</BusyButton>
				) : (
					<Button
						disabled={!newWatchlistName.replace(/ /g, "").length}
						type="submit"
						bsStyle="primary"
					>
						Add
					</Button>
				)}
			</span>
		</InputGroup>
	</Form>
);

export default AdderForm;
