import React from "react";
import { sortBy, groupBy } from "lodash";
import TaxesHeader from "./header";
import TaxesGroup from "./group";
import TaxesFooter from "./footer";
import TaxesNotes from "./notes";
import TextPlaceholder from "../../../../text-placeholder";
import SectionHead from "../../section-head";
import LineItemsContainer from "../../line-items/container";

const PCell = () => (
	<td className="txt-number">
		<TextPlaceholder />
	</td>
);

const Taxes = props => {
	const {
		isLoading,
		isFetched,
		taxes,
		isAdjudicationSale,
		isTaxSale,
		bidding: { isTraditional } = { isTraditional: undefined },
		taxAuthority
	} = props;

	// HACK: Shelby County calls Taxes "Amount Due"
	// Rename the col header to "amount due" per Brian and Matthew's request
	const taxesName =
		taxAuthority && taxAuthority.code === "SHB" ? "Amount Due" : "Taxes";

	// HACK: Shreveport calls City Penalty "DDD Tax"
	const penaltyName = taxAuthority
		? taxAuthority.code === "SHR"
			? "DDD Tax"
			: "City Penalty"
		: null;

	// HACK: show only this column for these TAs
	const onlyShowBalance =
		taxAuthority &&
		(taxAuthority.code === "SHB" || taxAuthority.code === "GCM");

	// HACK: shelby doesn't want any tax notes
	const hideNotes = taxAuthority && taxAuthority.code === "SHB";

	const taxesHeader = (
		<TaxesHeader {...{ onlyShowBalance, taxesName, penaltyName }} />
	);

	return (
		<LineItemsContainer>
			<SectionHead id="sh_delinquencies">Taxes</SectionHead>
			{isLoading || !isFetched ? (
				<table className="line-items__table">
					{taxesHeader}
					<tbody>
						<tr>
							<th scope="row" className="txt-date">
								<TextPlaceholder />
							</th>
							<td>
								<TextPlaceholder />
							</td>
							{onlyShowBalance ? (
								<PCell />
							) : (
								<>
									<PCell />
									<PCell />
									<PCell />
								</>
							)}
						</tr>
					</tbody>
					<TaxesFooter {...{ onlyShowBalance, taxes }} />
				</table>
			) : null}

			{isFetched && taxes && !taxes.length ? (
				<div className="msg-empty">
					<p>This property has no delinquencies.</p>
				</div>
			) : null}

			{isFetched && taxes && taxes.length ? (
				<table className="line-items__table">
					{taxesHeader}
					<tbody>
						{taxesByYear(taxes).map(group => (
							<TaxesGroup key={group.year} {...{ group, onlyShowBalance }} />
						))}
					</tbody>
					<TaxesFooter {...{ onlyShowBalance, taxes }} />
				</table>
			) : null}
			<TaxesNotes
				{...{
					hideNotes,
					isAdjudicationSale,
					isTaxSale,
					isTraditional
				}}
			/>
		</LineItemsContainer>
	);
};

const taxesByYear = taxes => {
	let groups = groupBy(taxes, function(tax) {
		return tax.year;
	});
	let results = [];

	for (let year in groups) {
		results.push({
			year: year,
			taxes: groups[year]
		});
	}

	return sortBy(results, "year").reverse();
};

export default Taxes;
