import React from "react";
import AuctionDocument from "./document";
import "./auction-docs.less";
import "../../../layout/less/_sprite-icon-ffmt.less";
import { withStateHandlers, compose } from "recompose";
import { formatNumber } from "accounting";
import TextPlaceholder from "../../../text-placeholder";
import SectionHead from "../section-head";
import LineItemsContainer from "../line-items/container";
import { withContentRect } from "react-measure";
import { get } from "lodash";
import { useFetch } from "react-fetch-hooks";

const withBounds = withContentRect("bounds");

const withShowMore = withStateHandlers(
	{ showHowMany: 20 },
	{
		handleShowMore: ({ showHowMany }) => () => {
			return { showHowMany: showHowMany + 20 };
		}
	}
);

const addDocumentProperties = document => {
	const { id, fileExtension, mailClass, taxAuthority } = document;
	const dims = {
		small: "24x26",
		medium: "75x75",
		large: "250x250"
	};

	const iconCssClasses = `icon-ffmt icon-ffmt-${fileExtension}`;

	const mailClassCssClass =
		mailClass === "Certified" ? "mail-certified" : "mail-firstclass";

	const downloadUrl = `/api/documents/${id}?taxAuthority=${taxAuthority}`;

	return {
		thumbUrl: {
			small: `/api/${id}/${dims.small}.jpg`,
			medium: `/api/${id}/${dims.medium}.jpg`,
			large: `/api/${id}/${dims.large}.jpg`
		},
		iconCssClasses,
		mailClassCssClass,
		downloadUrl
	};
};

const useDocumentData = id =>
	useFetch(id ? `/api/documents?auctionId=${id}&onlyImages=false` : null);

const Documents = ({
	id,
	showHowMany,
	handleShowMore,
	isFetched: isAuctionFetched,
	contentRect,
	measureRef
}) => {
	const { isFetching, body: documents } = useDocumentData(id);
	const width = get(contentRect, "bounds.width", 0);

	const isSmDown = width < 960;

	const docsHeaderRow = isSmDown ? null : (
		<thead>
			<tr>
				<th style={{ width: "10em" }} className="txt-date">
					Date
				</th>
				<th className="txt-description">Type</th>
				<th className="txt-description">Recipient</th>
				<th style={{ width: "10em" }} className="txt-action">
					Download
				</th>
			</tr>
		</thead>
	);

	const Inner = isSmDown ? "div" : "tbody";

	return (
		<LineItemsContainer ref={measureRef}>
			<SectionHead>Documents</SectionHead>

			{!isAuctionFetched || isFetching || !id ? (
				<DocsTable>
					<>
						{docsHeaderRow}
						<tbody>
							<DocRowPlaceholder />
						</tbody>
					</>
				</DocsTable>
			) : null}

			{isAuctionFetched && !isFetching && (!documents || !documents.length) ? (
				<div className="msg-empty">
					<p>This property has no documents.</p>
				</div>
			) : null}

			{documents && documents.length ? (
				<>
					<DocsTable isSmDown={isSmDown}>
						{docsHeaderRow}
						<Inner className="line-items__row-group">
							{documents.slice(0, showHowMany).map(doc => {
								doc = { ...doc, ...addDocumentProperties(doc) };
								return (
									<AuctionDocument
										key={doc.id}
										{...doc}
										isSmDown={isSmDown}
										className="doc-collection-item"
									/>
								);
							})}
						</Inner>
					</DocsTable>
					{documents && documents.length && showHowMany < documents.length ? (
						<>
							<p className="text-muted text-center">
								Showing {formatNumber(showHowMany)} of{" "}
								{formatNumber(documents.length)} documents
							</p>
							<button
								className="btn btn-block btn-link"
								onClick={handleShowMore}
							>
								Show more
							</button>
						</>
					) : null}
				</>
			) : null}
		</LineItemsContainer>
	);
};

const DocsTable = ({ children, isSmDown }) =>
	isSmDown ? (
		<div className="line-items__table">{children}</div>
	) : (
		<table style={{ tableLayout: "fixed" }} className="line-items__table">
			{children}
		</table>
	);

const DocRowPlaceholder = () => (
	<tr>
		<td className="txt-date">
			<TextPlaceholder />
		</td>
		<td className="txt-description">
			<TextPlaceholder />
		</td>
		<td className="txt-description">
			<TextPlaceholder />
		</td>
		<td className="txt-action">
			<TextPlaceholder />
		</td>
	</tr>
);

export default compose(
	withShowMore,
	withBounds
)(Documents);
