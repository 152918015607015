import React, { Component } from "react";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import WatchlistItem from "./watchlist-item";

class WatchlistsViewer extends Component {
	componentDidMount() {
		this.props.fetchWatchlists();
	}

	render() {
		const { watchlists = {}, ...props } = this.props;
		const { isFetched: isLoaded, data: items } = watchlists;

		if (!isLoaded) return null;

		return (
			<div>
				{items.map(item => (
					<WatchlistItem key={item.name} {...item} {...props} />
				))}
			</div>
		);
	}
}

const styles = {
	item: {
		padding: [0, bootstrap.paddingBaseHorizontal]
	}
};

export default useSheet(styles)(WatchlistsViewer);
