import React from "react";
import { Fade } from "react-bootstrap";
import useSheet from "react-jss";
import styles from "./index.styles";

const HoverHUD = ({
	isEditing,
	isSaving,
	isHover,
	justSaved,
	onEdit,
	classes
}) => {
	return (
		<Fade in={!isEditing && !isSaving && isHover && !justSaved}>
			<div className={classes.fadeInner}>
				<div className={classes.clickToEdit} onClick={onEdit}>
					<small>Click to edit</small>
				</div>
				<div className={classes.intentIcon} onClick={onEdit}>
					<i className="fa fa-pencil" />
				</div>
			</div>
		</Fade>
	);
};

export default useSheet(styles)(HoverHUD);
