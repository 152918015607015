import React from "react";
import CartDepositButton from "./cart-deposit-button";

const Submit = props => {
	return (
		<>
			<button
				disabled={props.isDepositPlacing}
				className="btn btn-link"
				onClick={props.handleClose}
			>
				Don't place
			</button>
			<CartDepositButton {...props} onClick={props.handlePlaceDeposit} />
		</>
	);
};

export default Submit;
