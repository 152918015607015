import React from "react";
import "./no-map.less";

const NoMap = () => (
	<div className="map__no-map">
		<div className="map__no-map__inner text-center">
			<h3>Map Unavailable</h3>
			<i className="fa fa-4x fa-ban map__no-map__icon" />
			<p>
				<small>
					This property cannot be displayed on a map because either the address
					is unknown or the geocoding accuracy is above street level for this
					area.
				</small>
			</p>
		</div>
	</div>
);

export default NoMap;
