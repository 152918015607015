import React from "react";
import { get } from "lodash";
import connect from "./connect";

const AdjCert = ({ taxAuthority, isAdjudicationSale, adjCertificateDoc }) => {
	const adjCertDocId = get(adjCertificateDoc, "data[0].id");
	const showAdjCertificateLink = isAdjudicationSale && !!adjCertDocId;

	if (!showAdjCertificateLink) return null;

	const downloadUrl = `/api/documents/${adjCertDocId}?taxAuthority=${
		taxAuthority.code
	}`;

	const icon = <i className="adjudication-certificate__icon" />;

	return (
		<small>
			<a
				download
				className="btn btn-xs btn-link"
				href={downloadUrl}
				data-noroute
			>
				{icon} Adjudication Certificate
			</a>
		</small>
	);
};

export default connect(AdjCert);
