import { useFetch } from "react-fetch-hooks";
import { groupBy, sumBy } from "lodash";

const useCostData = lotNumber => {
	let { body: costs, isFetching, isFetched, error } = useFetch(
		`${process.env.ADMIN_API_URL}auctions/${lotNumber}/costs/`
	);

	if (!costs || !costs.length) {
		costs = [];
	} else {
		costs = costs.filter(c => !c.isDeleted);
		const grouped = groupBy(costs, c => c.code);

		let results = [];

		for (const code in grouped) {
			const group = grouped[code];

			results.push({
				id: group[0].id,
				code: Number(code),
				quantity: group.length,
				description: group[0].name,
				amount: sumBy(group, c => c.revenue)
			});
		}

		costs = results;
	}

	return {
		isLoading: isFetching,
		isLoaded: isFetched,
		isError: !!error,
		costs,
		total: sumBy(costs, c => c.amount)
	};
};

export default useCostData;
