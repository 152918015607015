import React, { useCallback, useState } from "react";
import { useMessageHandler } from "../../../channel/context";

import moment from "moment";

const useTimeSyncer = () => {
	const [isTimeOff, setTimeOff] = useState(false);

	const messageHandler = useCallback(({ type, body }) => {
		if (type == "Ping") {
			// calculate time offset in milliseconds
			const ms = Math.abs(
				Math.floor(moment.utc(new Date()).diff(moment(body)))
			);

			// if time offset greater than threshold, then set timeoff flag
			setTimeOff(ms >= 60000);
		}
	}, []);

	useMessageHandler(messageHandler);

	return isTimeOff;
};

const TimeDiff = () => {
	const clientTimeIsOff = useTimeSyncer();

	if (!clientTimeIsOff) return null;

	return (
		<div className="alert alert-danger">
			<div className="text-center">
				<div>
					The time on your device is out of sync with the time on our servers.
					<br />
					Information about auction end times will not be accurate.
				</div>
			</div>
		</div>
	);
};

export default TimeDiff;
