import React from "react";
import moment from "moment";

const PurchaseDetails = props => {
	const {
		certificateInfo: { documentId, filedOn, status },
		taxAuthority: { code }
	} = props;

	const isFiled = status === "Filed";
	const friendly = status.replace(/([A-Z])/g, " $1").toLowerCase();
	const displayStatus = status
		? `Certificate ${friendly}${
				isFiled ? ` on ${moment(filedOn).format("L")}` : ""
		  }`
		: "";

	const link = documentId
		? `/api/documents/${documentId}?taxAuthority=${code}`
		: null;

	return (
		<div>
			<a download={documentId} href={link} title="Download Certificate">
				<i
					className="prop-details__icon-pdf--small"
					title="PDF"
					aria-label="PDF"
				/>
				Download
			</a>

			<br />
			<span className="small">{displayStatus}</span>
		</div>
	);
};

export default PurchaseDetails;
