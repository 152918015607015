import React from "react";
import { graphql } from "gatsby";
import { Router } from "@reach/router";
import AuctionDetails from "./details";

const AuctionDetailsPage = ({
	data: { civicSourceTaxAuthority: taxAuthority }
}) => (
	<Router>
		{/* for statically rendering base template for TA */}
		<AuctionDetailsPath
			path={`/auctions/${taxAuthority.code.toLowerCase()}`}
			taxAuthority={taxAuthority}
		/>

		<AuctionDetailsPath
			path={`/auctions/${taxAuthority.code.toLowerCase()}/:lotNumber`}
			taxAuthority={taxAuthority}
		/>
	</Router>
);

const AuctionDetailsPath = ({ taxAuthority, lotNumber, ...props }) => (
	<AuctionDetails
		lotNumber={lotNumber ? `${taxAuthority.code}${lotNumber}` : null}
		taxAuthority={taxAuthority}
		{...props}
	/>
);

export default AuctionDetailsPage;

export const pageQuery = graphql`
	query AuctionDetailsQuery($code: String!) {
		civicSourceTaxAuthority(code: { eq: $code }) {
			id
			code
			name
			dontAllowAdjoiningLandowners
			feeModel
			referenceNumberName
			biddingPrerequisites {
				bankAccount
				driversLicenseNumber
				bidderNumber
			}
			w9 {
				requiresW9
				processingInstructions
				w9FormUrl
				email
				faxNumber
				physicalAddress
				mailingAddress
			}
		}
	}
`;
