import React from "react";
import Details from "./details";
import Map from "./map";

const AuctionInfo = props => {
	return (
		<div>
			<div className="prop-details__info-map__container">
				<div className="clearfix">
					<div className="prop-details__header-cols__summary">
						<Details {...props} />
					</div>
					<div className="prop-details__header-cols__map">
						<div className="row">
							<Map {...props} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuctionInfo;
