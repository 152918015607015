import React from "react";
import C from "../amount-cell";

const TaxesGroup = ({ group, onlyShowBalance }) => (
	<>
		{group.taxes
			? group.taxes.map(
					(
						{ type, year, balance, interest, penalty, collection, total },
						i
					) => (
						<tr key={`${type.name}${type.year}`}>
							{i ? null : (
								<th
									scope="row"
									className="txt-date"
									rowSpan={group.taxes.length}
								>
									{year}
								</th>
							)}
							<td className="txt-description">{type.name}</td>
							<C amount={balance} />
							{onlyShowBalance ? null : (
								<>
									<C amount={interest} />
									<C amount={penalty} />
									<C amount={collection} />
									<C amount={total} isTotal />
								</>
							)}
						</tr>
					)
			  )
			: null}
	</>
);

export default TaxesGroup;
