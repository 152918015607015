import { connect } from "fetch-helpers";
import { flatten, map, uniqBy } from "lodash";

const onResult = data => {
	let parties = flatten(map(data, "interestedParties")); // slurp out interested parties
	parties = uniqBy(parties, "id"); // don't show duplicates
	return parties.map(({ id, name, bestAddress }) => {
		return {
			id,
			displayName: !name
				? ""
				: name.first && name.last
				? `${name.first} ${name.last}`
				: name.business || name.unparsed,
			address: bestAddress ? bestAddress.value : ""
		};
	});
};

export default function interestedPartyFetcher(ComponentToWrap) {
	const URL = process.env.RESEARCH_API_URL;
	return connect(({ taxAuthority, accountNumber }) =>
		taxAuthority && accountNumber
			? {
					interestedParties: {
						url: `${URL}properties?group=${
							taxAuthority.code
						}&accountNumber=${accountNumber}`,
						onData: async ({ id }) => {
							const res = await fetch(`${URL}properties/${id}/instruments`);
							const parsed = await res.json();
							return onResult(parsed);
						},
						keys: ["taxAuthority", "accountNumber"]
					}
			  }
			: null
	)(ComponentToWrap);
}
