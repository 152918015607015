import { connect } from "fetch-helpers";

export default function imageFetcher(ComponentToWrap) {
	return connect(({ user }) =>
		user && user.username
			? {
					bankAccounts: {
						url: `${process.env.ADMIN_API_URL}/profiles/${
							user.username
						}/bankaccounts/`,
						keys: ["user"],
						bearerToken: user ? user.token : null,
						onData: data =>
							data.map(acct => {
								acct.display = `${acct.nickname} (${
									acct.accountNumber.maskedAccountNumber
								})`;
								return acct;
							})
					}
			  }
			: null
	)(ComponentToWrap);
}
