import React from "react";
import { Video } from "@civicsource/ui";
import { withStateHandlers } from "recompose";
import TextPlaceholder from "../../../text-placeholder";

const AuctionTypeHelpVideo = ({
	isAdjudicationSale,
	isTaxSale,
	stateFipsCode,
	isFetched,
	isModalOpen,
	handleOpenModal,
	handleCloseModal
}) => {
	if (isFetched) {
		const videoObj = isAdjudicationSale
			? {
					title: "Adjudicated Property & Tax Deed Sale Tutorial",
					id: "2AKAnBrDx4g"
			  }
			: isTaxSale && stateFipsCode === "22"
			? {
					title: "Louisiana Tax Certificate Sales Process",
					id: "NObWnNLCtxY"
			  }
			: null;
		const whatIsThis = videoObj ? (
			<a
				className="help-link"
				title={videoObj.title}
				href={`https://www.youtube.com/watch?v=${videoObj.id}`}
				onClick={handleOpenModal}
			>
				What is this?
			</a>
		) : null;

		const player = videoObj ? (
			<Video.Player
				video={videoObj}
				show={isModalOpen}
				onHide={handleCloseModal}
				bsSize="large"
			/>
		) : null;

		return videoObj ? (
			<>
				{player}
				{whatIsThis}
			</>
		) : null;
	}
	return (
		<a className="help-link">
			<TextPlaceholder />
		</a>
	);
};

const withVideoModalState = withStateHandlers(
	{ isModalOpen: false },
	{
		handleOpenModal: () => e => {
			e && e.preventDefault && e.preventDefault();
			return { isModalOpen: true };
		},
		handleCloseModal: () => () => ({ isModalOpen: false })
	}
);

export default withVideoModalState(AuctionTypeHelpVideo);
