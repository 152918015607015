import React from "react";
import { formatMoney } from "accounting";
import useSheet from "react-jss";

const LineItemCell = ({ amount, isTotal, classes }) => (
	<td
		className={isTotal ? classes.total : amount ? classes.amount : classes.zero}
	>
		{formatMoney(amount)}
	</td>
);

const styles = {
	amount: {
		textAlign: "right"
	},
	zero: {
		composes: "$amount",
		color: "#a3a7ab"
	},
	total: {
		composes: "$amount",
		fontWeight: "bold"
	}
};

export default useSheet(styles)(LineItemCell);
