import React from "react";
import TimeDiff from "./time-diff";
import BidderListApplication from "./bidderlist-application";
import Prerequisites from "./prerequisites";
import OtherAuctionAlert from "./other-auction";

const AuctionAlerts = p => {
	return (
		<>
			<TimeDiff />
			<BidderListApplication {...p} />
			<Prerequisites {...p} />
			<OtherAuctionAlert {...p} />
		</>
	);
};

export default AuctionAlerts;
