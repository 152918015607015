import React from "react";
import moment from "moment";
import AuctionTime from "../../time";
import { Helmet } from "react-helmet";
import Info from "../info-nugget";
import TextPlaceholder from "../../../text-placeholder";

const DateTime = props => {
	const {
		startDate,
		endDate,
		isAdjudicationSale,
		videoLink,
		hasDeposit,
		isOffline,
		sale,
		status,
		lotNumber
	} = props;
	let dateTime = null;

	if (status) {
		if (!status.isCancelled && (!startDate || !endDate) && isAdjudicationSale) {
			dateTime = (
				<Info
					label="Date/Time:"
					content={
						<>
							TBD &mdash;{" "}
							{status.isPreResearch && !hasDeposit
								? "Waiting for deposit"
								: null}
							{status.isPreSale ? "Pending research/noticing" : null}
							{videoLink}
						</>
					}
				/>
			);
		}

		if (!status.isCancelled && (startDate || endDate)) {
			dateTime = (
				<>
					<Helmet>
						{startDate ? (
							<meta
								itemProp="availabilityStarts"
								content={moment(startDate).format("L LT")}
							/>
						) : null}
						{endDate ? (
							<meta
								itemProp="availabilityEnds"
								content={moment(endDate).format("L LT")}
							/>
						) : null}
					</Helmet>
					<Info
						label="Date/Time:"
						content={
							<AuctionTime
								auction={props.auction}
								customPlaceholder={
									<TextPlaceholder likePhrase="The auction has not" />
								}
								lotNumber={lotNumber}
								showHelp
								showGoing={false}
							/>
						}
					/>
				</>
			);
		}

		if (isOffline) {
			if (!status.isCancelled && startDate) {
				dateTime = (
					<>
						<Info
							label="Date/Time:"
							content={moment(startDate).format("L LT")}
						/>
						<Info label="Offline Location:" content={sale.location} />
					</>
				);
			}
		}
	}
	return dateTime;
};

export default DateTime;
