import React, { Component } from "react";

import { LinkPseudo } from "@civicsource/ui";
import useSheet from "react-jss";
import TextArea from "react-textarea-autosize";
import styles from "./index.styles";
import NoteIcon from "./icon";
import EditingHUD from "./editing-hud";
import HoverHUD from "./hover-hud";

import connect from "./connect";

export const isNullOrWhitespace = s => !s || !s.trim();

class NoteEditor extends Component {
	state = {
		note: this.props.note,
		isEditing: false
	};

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.isEditing && this.state.isEditing) {
			document.addEventListener("click", this.handleDocumentClick);
		}

		if (prevState.isEditing && !this.state.isEditing) {
			document.removeEventListener("click", this.handleDocumentClick);
		}

		if (!prevProps.isSaving && this.props.isSaving) {
			this.setState({ isEditing: false });
		}

		if (prevProps.isSaving && this.props.isSavingError) {
			this.setState({ isEditing: true });
		}

		// Focus the textarea on edit
		if (!prevState.isEditing && this.state.isEditing) {
			const textAreaEl = document.getElementById(
				`note-editor__textarea--${this.props.lotNumber}`
			);
			textAreaEl && textAreaEl.focus();
		}

		// Focus the icon on emptying/canceling edit of an empty note
		if (
			(document.activeElement === document.body &&
				(prevState.isEditing && !this.state.isEditing && !this.props.note)) ||
			(prevProps.isSaving && !this.props.isSaving && !this.props.note)
		) {
			const iconEl = document.getElementById(
				`note-editor__icon--${this.props.lotNumber}`
			);
			iconEl && iconEl.focus();
		}
	}

	componentWillUnMount() {
		document.removeEventListener("click", this.handleDocumentClick);
	}

	handleDocumentClick = ({ target }) => {
		// Handle commiting/discarding changes on clicking outside the editor
		const props = this.props;

		const container = document.getElementById(
			`note-editor--${props.lotNumber}`
		);
		if (
			container &&
			target !== container &&
			container.contains &&
			!container.contains(target)
		) {
			if (this.state.note === props.note) {
				// Discard if no changes
				this.onCancelEdit();
			} else {
				// Commit if dirty
				this.onSave();
			}
		}
	};

	onEdit = () => {
		this.setState({ isEditing: true });
	};

	onCancelEdit = () => {
		// Revert local note value state & mark not editing
		this.setState((prevState, { note }) => ({
			isEditing: false,
			note
		}));
	};

	onChange = ({ target: { value } }) => {
		this.setState({ isEditing: true, note: value });
	};

	onKeyUp = ({ which }) => {
		// Cancel & revert on esc key
		if (which === 27) this.onCancelEdit();
	};

	onKeyDown = e => {
		// Save on enter key, line break if shift + enter
		if (e.which === 13 && !e.shiftKey) {
			e.preventDefault();
			this.onSave();
		}
	};

	onSave = e => {
		if (e && e.preventDefault) e.preventDefault();

		if (
			this.props.note === this.state.note ||
			(!this.props.note && isNullOrWhitespace(this.state.note))
		) {
			// Don't request the API remove a non-existent note,
			// or commit a no-op
			this.onCancelEdit();
		} else {
			this.props.saveNote(
				this.props.lotNumber,
				isNullOrWhitespace(this.state.note) ? null : this.state.note
			);
		}
	};

	render() {
		if (!this.props.user) return null;

		const {
			onMouseEnter,
			onMouseLeave,
			onFocus,
			onBlur,
			isFocus,
			isHover,

			isSaving,
			isSavingError,
			justSaved,

			classes,
			lotNumber,
			note,
			maxLength
		} = this.props;

		const { isEditing } = this.state;

		const noteIcon = (
			<NoteIcon
				id={`note-editor__icon--${lotNumber}`}
				note={note}
				onEdit={this.onEdit}
			/>
		);

		if (
			!note &&
			!isEditing &&
			!isSaving &&
			isNullOrWhitespace(this.state.note)
		) {
			return noteIcon;
		}

		const charsLeft = this.state.note
			? maxLength - this.state.note.length
			: maxLength;

		const textAreaClassName = isEditing
			? classes.editing
			: isFocus || isHover
			? classes.intent
			: classes.container;

		return (
			<form
				id={`note-editor--${lotNumber}`}
				onSubmit={this.onSave}
				className={classes.outer}
				{...{ onMouseEnter, onMouseLeave, onFocus, onBlur }}
				onKeyUp={this.onKeyUp}
				onKeyDown={this.onKeyDown}
			>
				<TextArea
					id={`note-editor__textarea--${lotNumber}`}
					className={textAreaClassName}
					onClick={this.onEdit}
					onFocus={this.onEdit}
					onChange={this.onChange}
					value={this.state.note}
					maxLength={maxLength}
				/>
				<EditingHUD
					onCancelEdit={this.onCancelEdit}
					{...{ isEditing, isSaving, isSavingError, charsLeft, justSaved }}
				/>
				{justSaved ? (
					<div className={classes.justSaved}>
						<i className="fa fa-check" /> Saved!
					</div>
				) : null}
				<HoverHUD
					onEdit={this.onEdit}
					{...{ isEditing, isSaving, isHover, justSaved }}
				/>
			</form>
		);
	}
}

NoteEditor.defaultProps = {
	maxLength: 250
};

const NoteEditorWithPseudo = props => (
	<LinkPseudo>
		<NoteEditor {...props} />
	</LinkPseudo>
);

export default connect(useSheet(styles)(NoteEditorWithPseudo));
