import React from "react";

const TaxesNotes = ({
	hideNotes,
	isAdjudicationSale,
	isTaxSale,
	isTraditional
}) =>
	!hideNotes && !isAdjudicationSale ? (
		<div className="line-items__note__container">
			<h4>Note:</h4>
			<p className="text-justify">
				{isTaxSale ? (
					<>
						The tax sale costs and interest listed reflect the associated costs
						and fees to date. Additional interest may accrue prior to the tax
						sale opening, causing the interest and total amounts to increase.
						{isTraditional ? (
							<>The starting bid will reflect these increases.</>
						) : (
							<>
								The purchase price during the time of the sale will reflect
								these increases. Following notification of your successful bid
								and receipt of payment, availability of the tax sale certificate
								through your CivicSource.com account will be confirmation that
								the tax sale recordation process has been completed. Failure to
								pay additional tax delinquencies not for sale through this web
								site may result in this property being offered at a subsequent
								tax sale. You should contact the tax authority for information
								regarding additional delinquencies.
							</>
						)}
					</>
				) : (
					<>
						The sale costs and interest listed reflect the associated costs and
						fees due to date, as well as the estimated costs associated with
						pre-sale due process notification compliance requirements.
						Additional costs and/or interest may accrue prior to the sale
						opening, causing the total amounts to increase. The purchase price
						during the time of the sale will reflect these increases. Following
						notification of your successful bid and receipt of payment,
						availability of the Non-Warranty Cash Sale Certificate through your
						CivicSource.com account will be confirmation that the sale-related
						recordation process has been completed. Failure to pay additional
						tax delinquencies not for sale through this web site may result in
						this property being offered at a subsequent tax sale. You should
						contact the tax authority for information regarding additional
						delinquencies.
					</>
				)}
			</p>
		</div>
	) : null;

export default TaxesNotes;
