import React from "react";
import { lowerCase, get, find } from "lodash";

export const getDefaultCaveats = lineItems => {
	const assessments = get(lineItems, "data.assessments");
	if (!assessments) return {};

	const land = find(assessments, function(ass) {
		return lowerCase(get(ass, "type.name")) === "land";
	});

	const improvement = find(assessments, function(ass) {
		return lowerCase(get(ass, "type.name")) === "improvement";
	});

	return {
		isMaybeVacant:
			land && improvement && improvement.amount === 0 && land.amount !== 0,
		isMayNotIncludeLand:
			land && improvement && improvement.amount !== 0 && land.amount === 0
	};
};

const Caveats = props => {
	const { caveats, lineItems } = props;

	if (!lineItems || !lineItems.data) return null;

	const { isMaybeVacant, isMayNotIncludeLand } = getDefaultCaveats(lineItems);

	return caveats.length > 0 || isMaybeVacant || isMayNotIncludeLand ? (
		<ul className="fa-ul small text-danger">
			{isMaybeVacant ? (
				<li>
					<i className="fa fa-li fa-warning" /> Based on the assessment, this
					land may be a vacant lot.
				</li>
			) : null}

			{isMayNotIncludeLand ? (
				<li>
					<i className="fa fa-li fa-warning" /> Based on the assessment, this
					auction may not include land.
				</li>
			) : null}

			{caveats.map(caveat => (
				<li key={caveat}>
					<i className="fa fa-li fa-warning" /> {caveat}
				</li>
			))}
		</ul>
	) : null;
};

export default Caveats;
