import React from "react";
import { Alert } from "react-bootstrap";

const ErrorBanner = () => {
	return (
		<Alert bsStyle="danger">
			<div className="text-center">
				<h4>Problem Submitting Application</h4>
				<p>
					There was an error submitting your application to bid. Please refresh
					the page and try again, or if the problem persists,{" "}
					<a className="alert-link" href="/help">
						contact us.
					</a>
				</p>
			</div>
		</Alert>
	);
};

export default ErrorBanner;
