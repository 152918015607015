import React from "react";
import { get } from "lodash";
import moment from "moment";
import { formatMoney } from "accounting";
import NoteEditor from "../../../note-editor";
import Address from "./address";
import Bidding from "./bidding";
import DateTime from "./date-time";
import Caveats from "./caveats";
import AuctionTypeHelpVideo from "./auction-type-help-video";
import Info, { InfoDivider } from "../info-nugget";
import TextPlaceholder from "../../../text-placeholder";
import MakeDeposit from "./make-deposit";
import InfoDetailsPlaceholder from "./details-placeholder";
import PurchaseDetails from "./purchase-details";

import { SellerLink, AuctionTypeLink, SaleLink } from "./search-links";

const Details = props => {
	const {
		sale,
		auctionType,
		address,
		lotNumber,
		hasDeposit,
		stateFipsCode,
		countyFipsCode,
		cityFipsCode,
		sellerNotesHtml,
		biddingFormatDescription,
		hasRedemptivePeriod,
		redemptivePeriodDescription,
		isAdjudicationSale,
		isTaxSale,
		isSecondaryMarket,
		taxAuthority,
		taxAuthorityDisplayName,
		user,
		isAdjoiningLandOwner,
		timeline,
		depositPrice,
		status,
		auction,
		isFetched,
		certificateInfo
	} = props;

	if (isFetched) {
		const showDepositPanel =
			hasDeposit &&
			(status.isPreResearch ||
				status.isPreSale ||
				status.isDuringSale ||
				status.isPostSale);

		const auctionTypeDisplay = isFetched ? (
			isAdjudicationSale ? (
				"Property"
			) : isTaxSale ? (
				"Investment"
			) : null
		) : (
			<TextPlaceholder />
		);

		const videoLink = (
			<AuctionTypeHelpVideo
				{...{ isAdjudicationSale, isTaxSale, stateFipsCode, isFetched }}
			/>
		);

		const currentDepositInfo = (
			<Info
				label="Deposit:"
				content={
					showDepositPanel ? (
						<>
							<span itemProp="price" className="price">
								{formatMoney(depositPrice)}
							</span>{" "}
							{videoLink}
							{hasDeposit ? (
								<>
									<br />
									<small>
										Placed on{" "}
										<time
											dateTime={moment(get(timeline, "depositedOn")).format()}
										>
											{moment(get(timeline, "depositedOn")).format("L")}
										</time>
									</small>
								</>
							) : null}
						</>
					) : (
						<>No Deposit {videoLink}</>
					)
				}
			/>
		);

		const placeDepositUi = !hasDeposit ? (
			<Info
				content={
					<div className="make-deposit__outter">
						<div className="make-deposit__container">
							<MakeDeposit {...props} />
						</div>
					</div>
				}
			/>
		) : isAdjoiningLandOwner && hasDeposit ? (
			<Info label="Deposited By:" content="Adjoining Landowner" />
		) : null;

		const isGCM = taxAuthority && taxAuthority.code === "GCM";

		return (
			<div>
				<div className="details-summary__container">
					<ul className="prop-details__summary__container">
						<Address
							{...{
								isFetched,
								isAdjudicationSale,
								isTaxSale,
								isSecondaryMarket,
								sale,
								lotNumber,
								taxAuthorityDisplayName,
								address,
								auction,
								user
							}}
						/>
						<Bidding {...props} />
						<DateTime {...{ ...props, videoLink }} />
						<InfoDivider />

						{isFetched && isAdjudicationSale ? (
							<>
								{currentDepositInfo}
								{placeDepositUi}
							</>
						) : null}
						{user ? (
							<Info
								label="Note:"
								content={
									<NoteEditor
										key={user.note}
										lotNumber={lotNumber}
										note={user.note}
									/>
								}
							/>
						) : null}
						<Info
							label="Seller:"
							content={
								<>
									{taxAuthorityDisplayName}
									<br />
									<SellerLink
										{...{ stateFipsCode, countyFipsCode, cityFipsCode }}
									>
										See other auctions from this seller
									</SellerLink>
								</>
							}
						/>
						{sellerNotesHtml ? (
							<Info
								label="Seller Notes:"
								content={
									<div className="auction-details__seller-notes">
										<span
											dangerouslySetInnerHTML={{ __html: sellerNotesHtml }}
										/>
									</div>
								}
							/>
						) : null}

						<Info
							label="Market:"
							content={
								isFetched ? (
									isSecondaryMarket ? (
										"Secondary"
									) : (
										"Primary"
									)
								) : (
									<TextPlaceholder />
								)
							}
						/>
						<Info
							label="Type:"
							content={
								<>
									{auctionTypeDisplay} {videoLink}
									{isGCM ? null : (
										<>
											<br />
											<AuctionTypeLink type={auctionType}>
												See other auctions of this type
											</AuctionTypeLink>
										</>
									)}
								</>
							}
						/>
						<Info
							label="Format:"
							content={
								isFetched ? biddingFormatDescription : <TextPlaceholder />
							}
						/>
						{isTaxSale ? (
							<Info
								label="Sale:"
								content={
									<SaleLink
										{...{
											stateFipsCode,
											countyFipsCode,
											cityFipsCode,
											type: auctionType
										}}
									>
										{get(sale, "name")}
									</SaleLink>
								}
							/>
						) : null}
						{hasRedemptivePeriod ? (
							<Info
								label="Redemptive Period:"
								content={redemptivePeriodDescription}
							/>
						) : null}
						{certificateInfo &&
						certificateInfo.filedOn &&
						certificateInfo.documentId &&
						certificateInfo.filedOn &&
						user &&
						status.isSold &&
						isTaxSale &&
						auction.winningBid &&
						auction.winningBid.isYourBid ? (
							<Info
								label="Certificate"
								content={<PurchaseDetails {...props} />}
							/>
						) : null}
					</ul>
				</div>
				<br />
				<Caveats {...props} />
			</div>
		);
	}
	return <InfoDetailsPlaceholder {...{ lotNumber, isFetched }} />;
};

export default Details;
