import React from "react";
import Images from "./images";
import "./slider.css";
import SectionHead from "../section-head";
import LineItemsContainer from "../line-items/container";
import { useFetch } from "react-fetch-hooks";

const useImages = id =>
	useFetch(id ? `/api/documents?auctionId=${id}&onlyImages=true` : null);

const ImageSectionChrome = ({ id }) => {
	const images = useImages(id);

	return (
		<LineItemsContainer>
			<SectionHead>Images</SectionHead>
			<div className="gallery__container">
				<Images {...images} />
			</div>
		</LineItemsContainer>
	);
};

export default ImageSectionChrome;
