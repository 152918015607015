import { findIndex, findLastIndex, forEach } from "lodash";
import { mapProps } from "recompose";
import _steps from "./steps";

const massageTimeline = mapProps(props => {
	const timeCubeProps = {};
	const steps = _steps({ ...props, ...props.auction });

	steps.lastActive = findLastIndex(steps, function(step) {
		return step.isActive;
	});

	forEach(steps, function(step) {
		const indexOfStep = findIndex(steps, {
			name: step.name
		});
		if (!step.isPast) {
			step.isPast =
				indexOfStep < steps.lastActive || indexOfStep < steps.lastPast;
		}

		step.isActiveNotPast = step.isActive && !step.isPast;

		step.isLastActive =
			steps.lastActive >= 0
				? indexOfStep === steps.lastActive
				: indexOfStep === steps.lastPast;

		step.statusIcon = step.isPast
			? "fa-check"
			: step.isActive || step.isPending
			? "fa-refresh"
			: "";
	});

	steps.lastPast = findLastIndex(steps, function(step) {
		return step.isPast;
	});

	steps.lastActiveOrPast = Math.max(steps.lastPast, steps.lastActive);

	steps.lastPending = findLastIndex(steps, function(step) {
		return step.isPending;
	});

	timeCubeProps.progress = {};

	timeCubeProps.progress.active = {
		value: (steps.lastActiveOrPast + 1) / steps.length
	};

	timeCubeProps.progress.active.cssRight = `${100 *
		(timeCubeProps.progress.active.value
			? 1 - timeCubeProps.progress.active.value
			: 1)}%`;

	timeCubeProps.progress.past = {
		value:
			steps.lastActiveOrPast >= 0 ? steps.lastActiveOrPast / steps.length : 0
	};

	timeCubeProps.progress.past.cssRight = `${100 *
		(timeCubeProps.progress.past.value
			? 1 - timeCubeProps.progress.past.value
			: 1)}%`;

	timeCubeProps.progress.pending = {
		value: steps.lastPending >= 0 ? (steps.lastPending + 1) / steps.length : 0
	};

	timeCubeProps.progress.pending.cssRight = `${100 *
		(timeCubeProps.progress.pending.value
			? 1 - timeCubeProps.progress.pending.value
			: 1)}%`;

	timeCubeProps.steps = steps;

	return { isFetched: props.isFetched, ...timeCubeProps };
});

export default massageTimeline;
