import { connect } from "fetch-helpers";

export default function bidderListFetcher(ComponentToWrap) {
	return connect(({ taxAuthority, bidderListId, user }) => {
		if (!user) return {};
		const { id, token } = user;
		return taxAuthority && bidderListId
			? {
					terms: {
						url: `${process.env.ADMIN_API_URL}${
							taxAuthority.code
						}/bidderLists/${bidderListId}/users/${id}`,
						bearerToken: token
					}
			  }
			: null;
	})(ComponentToWrap);
}
