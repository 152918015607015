import React from "react";

const TaxesHeader = ({ onlyShowBalance, taxesName, penaltyName }) => (
	<thead>
		<tr>
			<th className="txt-date">Year</th>
			<th className="txt-description">Type</th>
			<th className="txt-number">{taxesName}</th>
			{onlyShowBalance ? null : (
				<>
					<th className="txt-number">Interest</th>
					<th className="txt-number">{penaltyName}</th>
					<th className="txt-number">Collection</th>
					<th className="txt-number">Total</th>
				</>
			)}
		</tr>
	</thead>
);

export default TaxesHeader;
