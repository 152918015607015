import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import AddressBuilder from "./address-builder";
import CartDepositButton from "../components/cart-deposit-button";
import Nevermind from "./nevermind";
import affidavit from "./adjoining-landowner-affidavit.pdf";
import "./verify.css";

const AdjoiningLandownerVerify = props => {
	const {
		taxAuthority,
		user,
		showPopover,
		target,
		handleTogglePopover,
		handleOpenDepositModal
	} = props;

	const show = !taxAuthority.dontAllowAdjoiningLandowners;

	if (!show) return null;

	const areYouButton = (
		<label>
			<button
				className="btn btn-xs btn-link help-link"
				onClick={handleTogglePopover}
			>
				<strong>
					Are you an adjoining landowner?
					{showPopover ? (
						<i className="fa fa-chevron-down fa-fw" />
					) : (
						<i className="fa fa-chevron-right fa-fw" />
					)}
				</strong>
			</button>
		</label>
	);
	return (
		<>
			<div>
				{areYouButton}
				<Overlay show={showPopover} target={target} placement="bottom">
					<Popover
						id="adjoining-landowner-popover"
						style={{
							width: 800,
							maxWidth: 800,
							zIndex: 0,
							marginLeft: 180
						}}
					>
						<div>
							<h4>Adjoining Landowner</h4>
							{user ? (
								<p>
									Pursuant to La. R.S. 47:2202B, an adjudicated property may be
									sold to the current owner of an adjoining property who has
									been maintaining the adjudicated property for at least one
									year, without going to a public auction. If you meet these
									eligibility requirements and desire to exercise your right to
									purchase this property without an auction, please complete{" "}
									<strong>and have notarized</strong>{" "}
									<a target="_blank" rel="noopener noreferrer" href={affidavit}>
										this affidavit
									</a>{" "}
									and mail to the address below.
									<br />
									<br />
									Please note, if the Assessor of your Parish does not show you
									to be a current owner of the adjoining property, you must also
									submit adequate documentation proving your ownership of that
									property before your application will be processed. Contact
									your Assessor before submitting your application if you are
									unsure whether you are listed as an owner.
									<br />
									<br />
									CivicSource
									<br />
									Attn: Adjoining Landowner
									<br />
									New Orleans, LA 70150-0001
								</p>
							) : (
								<>
									<p>
										Pursuant to La. R.S. 47:2202B, an adjoining landowner may
										purchase adjudicated property prior to public bidding.
									</p>
									<p className="help-block">
										<strong>
											You must{" "}
											<a href={`/login?returnUrl=${window.location.pathname}`}>
												sign in
											</a>{" "}
											to enter adjoining address and place deposit
										</strong>
									</p>
								</>
							)}
						</div>

						{user ? (
							<>
								<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart__make-deposit__adjoining-landowner-verify__container">
									<div>
										<h5>
											<strong>
												Enter the address of your adjoining property
											</strong>
										</h5>
										<AddressBuilder {...props} />
										<hr />
									</div>
								</div>
								<div className="row text-center">
									<div className="col-sm-6">
										<Nevermind handleClick={handleTogglePopover} />
									</div>
									<div>
										<CartDepositButton
											{...props}
											onClick={handleOpenDepositModal}
											isAdjoiningLandowner
										/>
									</div>
								</div>
							</>
						) : null}
						<br />
					</Popover>
				</Overlay>
			</div>
			<br />
		</>
	);
};

export default AdjoiningLandownerVerify;
